import { updateSimphonyPasswordsSchema } from './update_simphony_passwords_schema';

app.InternalToolsView = app.BaseToolsView.extend({
  _refreshDayPartsClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton(
      $button.html(),
      'Making Request',
      'Refresh in Progress',
    );
    const url = '/api/v2/internal-tools/refresh-day-parts';
    app.makeRequest(
      'POST',
      url,
      null,
      () => {
        $button.loadingDidFinishSuccessfully(reqId);
      },
      () => {
        $button.loadingDidFinishWithError(reqId);
      },
    );
  },

  _syncEsperDevicesClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Syncing...', 'Synced!');
    const url = '/api/v2/internal-tools/sync-esper-devices';
    app.QueueHelper.makeRequest('POST', url, null, true, (err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
      }
    });
  },

  _updateSimphonyPasswordsClicked(e) {
    const $button = $(e.target);
    const detailsView = new app.SchemaDetailsView({
      title: 'Update Simphony Passwords',
      saveButtonTitles: ['Update Passwords', 'Updating...', 'Done!'],
      subProperty: 'options',
      schema: {
        fields: updateSimphonyPasswordsSchema.fields.options.fields,
      },
      callback(model) {
        const confirmed = app.promptUserToTypeInConfirmation(
          'Please type in "Update Passwords" to confirm that you want to update all Simphony passwords that match the given criteria.',
          'update passwords',
        );
        if (!confirmed) {
          return;
        }

        const reqId = $button.initLoadingButton($button.html(), 'Updating...', 'Done!');
        const url = '/api/v2/internal-tools/update-simphony-passwords';
        app.makeRequest(
          'POST',
          url,
          model.options,
          (finishData) => {
            $button.loadingDidFinishSuccessfully(reqId);
            new app.AlertView().show(`Update Simphony Passwords Results\n\n${finishData.result}`);
          },
          () => {
            $button.loadingDidFinishWithError(reqId);
          },
        );
      },
    });
    app.modalManager.showModalWithView(detailsView);
  },

  _intentionallyCrashClicked() {
    throw new Error('Intentional crash');
  },

  render() {
    app.BaseToolsView.prototype.render.apply(this, arguments);

    if (app.sessionUser.isBiteOps()) {
      this.addToolbarButton(
        'btn-primary',
        'Refresh Day Parts ⚒',
        this._refreshDayPartsClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-primary',
        'Sync Esper Devices ⚒',
        this._syncEsperDevicesClicked.bind(this),
      );
    }

    if (app.sessionUser.isBiteSupport()) {
      this.addToolbarButton(
        'btn-primary',
        'Update Simphony Passwords ⚒',
        this._updateSimphonyPasswordsClicked.bind(this),
      );
    }

    if (app.sessionUser.isBiteEng()) {
      this.addToolbarButton(
        'btn-primary',
        'Intentionally Crash ⚙',
        this._intentionallyCrashClicked.bind(this),
      );
    }

    return this;
  },
});
