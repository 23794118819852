import { allEnumValues, UserRight, UserRightHelper } from '@biteinc/enums';

import { apiTokenSchema } from './api_token_schema';

app.ApiToken = app.AbstractModel.extend({
  ModelName: 'apiToken',
  Schema: apiTokenSchema,

  displayName() {
    let name = this.get('firstName');
    if (this.hasStr('internalName')) {
      name += ` (${this.get('internalName')})`;
    }
    if (this.get('disabled')) {
      name += ' [Disabled]';
    }

    return name;
  },

  getOrgMemberships() {
    return this.get('orgMemberships') ?? [];
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'locationGroupId':
        return app.LocationGroupList.newSelectableList(app.locationGroupList, {
          includeOrgWide: true,
          includeAllLive: true,
          includeSingleLocationGroups: true,
        });
      case 'rights':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: UserRight,
          nameGenerator: this.nameFromUserRight,
          disclaimerGenerator: this.disclaimerFromUserRight,
          sort: app.AbstractCollection.SortOptions.ID,
          values: allEnumValues(UserRight).filter((right) => {
            return UserRightHelper.isApiTokenRight(right);
          }),
        });
    }
    return null;
  },

  nameFromUserRight(right) {
    switch (right) {
      case UserRight.ApiTokenMobileApp:
        return 'Mobile App';
      case UserRight.ApiTokenReporting:
        return 'Reporting';
      case UserRight.ApiTokenLocations:
        return 'Get Locations';
    }
    throw new Error(`unhandled user right: ${right}`);
  },

  disclaimerFromUserRight(right) {
    switch (right) {
      case UserRight.ApiTokenMobileApp:
        return 'Allows the API token to access endpoints used by the mobile app.';
      case UserRight.ApiTokenReporting:
        return 'Allows the API token to access reporting endpoints. This includes the endpoint for getting all locations associated with the token.';
      case UserRight.ApiTokenLocations:
        return 'Allows the API token to access endpoint to get locations of token.';
    }
    throw new Error(`unhandled user right: ${right}`);
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'locationGroupId': {
        const locationGroup = element;
        return locationGroup.displayNameAsListFieldElement(plainTextOnly);
      }
    }
    return displayName;
  },

  hasViewableJson() {
    return true;
  },

  canBeDestroyed() {
    return app.sessionUser.isBite();
  },

  save(data, options) {
    if (data.existingTokenId) {
      options.method = 'PUT';
      options.url = `${this.url()}/create-from-existing-token`;
    }
    app.AbstractModel.prototype.save.apply(this, [data, options]);
  },
});
