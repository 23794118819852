import _ from 'underscore';

import { IntegrationHelper } from '@biteinc/business-rules';
import { I9nSchemaBySystem } from '@biteinc/common';

import { getBadgeHtml } from '../../helpers/html_builder';

app.IntegrationListView = app.BaseListView.extend({
  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.collection, 'change', this._updateIfCanCreate);

    if (app.referenceIntegrationList && !app.referenceIntegrationList.hasBeenFetched()) {
      this.listenToOnce(app.referenceIntegrationList, 'reset', this.render);
      app.referenceIntegrationList.fetch({ reset: true });
    }
  },

  _updateIfCanCreate() {
    this.setCanCreate(app.location.getCreatableI9nSchemas().length > 0);
  },

  __getCellViewClass(integration) {
    if (integration.isPlaceholder()) {
      return app.IntegrationPlaceholderCellView;
    }
    return app.IntegrationCellView;
  },

  getListSections() {
    const posI9ns = [];
    const menuSupplementI9ns = [];
    const paymentI9ns = [];
    const compCardI9ns = [];
    const storedValueI9ns = [];
    const loyaltyI9ns = [];
    const fulfillmentI9ns = [];
    const tableTrackerI9ns = [];
    const otherI9ns = [];
    /** @type Record<import('@biteinc/common').I9nSchemaType, []> */
    const integrationGroupByType = {
      'comp-card': compCardI9ns,
      'ecomm-payment': paymentI9ns,
      'email-notifier': otherI9ns,
      fulfillment: fulfillmentI9ns,
      'table-tracker': tableTrackerI9ns,
      'kiosk-payment': paymentI9ns,
      loyalty: loyaltyI9ns,
      marketing: otherI9ns,
      pos: posI9ns,
      'sms-notifier': otherI9ns,
      'stored-value': storedValueI9ns,
      'supplementary-menu-source': menuSupplementI9ns,
    };

    this.collection.models.forEach((i9n) => {
      /** @type import('@biteinc/common').I9nSchema */
      const i9nSchema = i9n.getI9nSchema();
      integrationGroupByType[i9nSchema.type].push(i9n);
    });

    Object.entries(integrationGroupByType).forEach(([i9nSchemaType, integrationGroup]) => {
      if (integrationGroup.length) {
        return;
      }

      const referenceIntegration = app.referenceIntegrationList?.models.find((i9n) => {
        return i9n.getI9nSchema().type === i9nSchemaType;
      });
      if (referenceIntegration) {
        const placeholderIntegration = new app.Integration(
          this._getInitialIntegrationData(referenceIntegration.get('system')),
          {
            collection: this.collection,
          },
        );
        placeholderIntegration.setIsPlaceholder();
        integrationGroup.push(placeholderIntegration);
      }
    });

    const sections = [];
    if (posI9ns.length) {
      sections.push({ header: 'POS', models: posI9ns });
    }
    if (menuSupplementI9ns.length) {
      sections.push({ header: 'Menu Supplements', models: menuSupplementI9ns });
    }
    if (paymentI9ns.length) {
      sections.push({ header: 'Payment', models: paymentI9ns });
    }
    if (compCardI9ns.length) {
      sections.push({ header: 'Comp Card', models: compCardI9ns });
    }
    if (storedValueI9ns.length) {
      sections.push({ header: 'Stored Value', models: storedValueI9ns });
    }
    if (loyaltyI9ns.length) {
      sections.push({ header: 'Loyalty', models: loyaltyI9ns });
    }
    if (fulfillmentI9ns.length) {
      sections.push({ header: 'Fulfillment', models: fulfillmentI9ns });
    }
    if (tableTrackerI9ns.length) {
      sections.push({ header: 'Table Tracker', models: tableTrackerI9ns });
    }
    if (otherI9ns.length) {
      sections.push({ header: 'Others', models: otherI9ns });
    }

    return sections;
  },

  _getInitialIntegrationData(system) {
    const referenceIntegration = app.referenceIntegrationList?.models.find((i9n) => {
      return i9n.get('system') === system;
    });

    const initialSetDict = { system };

    if (!referenceIntegration) {
      return initialSetDict;
    }

    const i9nSchema = I9nSchemaBySystem[system];
    const fieldNames = IntegrationHelper.getCopyableFieldNamesFromReferenceIntegration(system, 0);
    return fieldNames.reduce((acc, fieldName) => {
      if (!referenceIntegration.has(fieldName)) {
        const fieldSchema = i9nSchema.fields[fieldName];
        const fieldIsBoolean = fieldSchema && fieldSchema.type === 'bool';

        if (fieldIsBoolean) {
          // Special case for booleans since an undefined value is often treated as false.
          // If we do not set the field to false, then it may instead use a default value of true.
          return {
            ...acc,
            [fieldName]: false,
          };
        }

        return acc;
      }

      return {
        ...acc,
        [fieldName]: referenceIntegration.get(fieldName),
      };
    }, initialSetDict);
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (this.options.canCreate) {
      const schemas = app.location.getCreatableI9nSchemas();
      const SchemaModel = app.AbstractModel.extend({
        displayNameHtml() {
          const title = getBadgeHtml(this.attributes);
          return `${title}<span>${this.displayName()}</span>`;
        },
      });
      const collection = app.AbstractCollection.createFromEnum({
        schema: schemas,
        ModelClass: SchemaModel,
        sort: app.AbstractCollection.SortOptions.NAME,
      });
      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });
      this.$('.card-header .right-button-container').html(dropdownView.render().$el);
      dropdownView.setTitle(
        `<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;new integration ${app.HtmlHelper.resellerRightIcon}`,
      );
      dropdownView.setup(collection, {
        onAdd: (i9nSchemaModel) => {
          const initialIntegrationData = this._getInitialIntegrationData(i9nSchemaModel.id);

          // eslint-disable-next-line new-cap
          const model = new this.collection.model(initialIntegrationData, {
            collection: this.collection,
          });
          this.editModel(model);
        },
      });

      if (app.sessionUser.canManageFullI9ns()) {
        const posI9n = _.find(this.collection.models, (i9n) => {
          return i9n.getI9nSchema().type === 'pos';
        });

        if (posI9n) {
          const $toolbar = $(
            // prettier-ignore
            '<div class="container integration-toolbar">' +
              '<div class="btn-group">' +
                '<div class="btn btn-secondary btn-sm sync-menus">' +
                  '<i class="bi bi-arrow-clockwise"></i>&nbsp;Sync Menus' +
                '</div>' +
              '</div>' +
            '</div>',
          );
          this.$listHeader.html($toolbar);
          const $button = $toolbar.find('.btn.sync-menus');
          $button.click(() => {
            const reqId = $button.initLoadingButton($button.html(), 'Syncing', 'Synced');
            // Endpoint will sync all pos-i9ns if we sync one of them
            posI9n.syncI9n((err, response) => {
              if (err) {
                $button.loadingDidFinishWithError(reqId);
              } else if (response?.message) {
                new app.AlertView().show(response.message, null, () => {
                  window.location.reload();
                });
                $button.loadingDidFinishWithError(reqId);
              } else {
                $button.loadingDidFinishSuccessfully(reqId);
                app.showSavedToastAndReload('Synced!', true);
              }
            });
          });
        }
      }
    }

    return this;
  },
});
