import async from 'async';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { IntegrationSystem } from '@biteinc/enums';
import { MathHelper } from '@biteinc/helpers';

app.MenuItemArray = app.AbstractModel.extend({
  initialize() {
    const listener = this.updateItemEventListeners;
    this.listenTo(this, 'change:items', listener);
    this.updateItemEventListeners();
  },

  isModGroup() {
    return this.collection.model === app.ModGroup;
  },

  isNotOnTheMenu() {
    return this.getParentCollection().hasBeenFetched() && !this.getVisibleInPlaces().length;
  },

  getParentCollection() {
    return this.isModGroup() ? app.menuItemList : app.menuStructureList;
  },

  getItemRefForId(itemId) {
    return _.find(this.get('items'), (itemRef) => {
      return itemRef._id === itemId;
    });
  },

  getItemDescription(itemId) {
    const i = _.findIndex(this.get('items'), (itemRef) => {
      return itemRef._id === itemId;
    });
    if (i < 0) {
      return null;
    }

    const itemRef = this.get('items')[i];

    // Create an array out of description string, to be joined into string before returning
    const desc = [this._getItemRefDescription(itemRef)];
    if (this.hasArr('integrations')) {
      if (this.isModGroup()) {
        const modifier = app.modList.get(itemRef._id);
        const posIdSummary = modifier?.getPosIdSummary();
        desc.push(`${posIdSummary}`);
      }
      const i9nItemRef = _.size(this.get('i9nItems')) > i ? this.get('i9nItems')[i] : null;
      const i9nDesc = this._getItemRefDescription(i9nItemRef);
      if (i9nDesc) {
        desc.push(`POS: ${i9nDesc}`);
      }
    }
    return desc.join('\n').trim();
  },

  _getItemRefDescription(itemRef) {
    if (!itemRef) {
      return '';
    }

    const parts = [];
    if (_.has(itemRef, 'hideFromMenu')) {
      parts.push(`hidden (${itemRef.hideFromMenu ? '✓' : '✘'})`);
    }
    if (_.has(itemRef, 'selectedByDefaultQuantity')) {
      parts.push(`selected by default count: ${itemRef.selectedByDefaultQuantity}`);
    }
    if (_.has(itemRef, 'name')) {
      parts.push(`name: ${itemRef.name}`);
    }
    (itemRef.localizationOverrides ?? []).forEach((localizationOverride) => {
      const localizationOverrideName = localizationOverride.overrideByFieldName.name;
      if (localizationOverrideName) {
        parts.push(`name [${localizationOverride.language}]: ${localizationOverrideName}`);
      }
    });

    if (_.has(itemRef, 'price')) {
      parts.push(`price: $${MathHelper.displayPrice(itemRef.price)}`);
    }
    if (itemRef.substitutionModGroup) {
      const substitutionModGroup = app.modGroupList.get(itemRef.substitutionModGroup.modGroupId);
      parts.push(`substitution mod group: ${substitutionModGroup.displayName()}`);
    }
    if (itemRef.colorScheme) {
      parts.push(`color: ${itemRef.colorScheme}`);
    }
    if (itemRef.nutritionInfo) {
      const nutritionInfo = itemRef.nutritionInfo;
      const falsyValues = [null, undefined];
      if (!falsyValues.includes(nutritionInfo.baseCalories)) {
        parts.push(`base calories: ${itemRef.nutritionInfo.baseCalories} cals`);
      }
      if (!falsyValues.includes(nutritionInfo.maxCalories)) {
        parts.push(`max calories: ${itemRef.nutritionInfo.maxCalories} cals`);
      }
      if (!falsyValues.includes(nutritionInfo.proteinContent)) {
        parts.push(`protein: ${itemRef.nutritionInfo.proteinContent} g`);
      }
      if (!falsyValues.includes(nutritionInfo.fatContent)) {
        parts.push(`fat: ${itemRef.nutritionInfo.fatContent} g`);
      }
      if (!falsyValues.includes(nutritionInfo.carbohydrateContent)) {
        parts.push(`carbohydrates: ${itemRef.nutritionInfo.carbohydrateContent} g`);
      }
    }
    if (itemRef.autoSelectedSubMods) {
      if (itemRef.autoSelectedSubMods.length) {
        const modId = itemRef.autoSelectedSubMods[0]._id;
        const mod = app.modList.get(modId);
        parts.push(`subMod: ${mod.displayName()}`);
      } else {
        parts.push('subMod ✘');
      }
    }
    if (itemRef.deselectionSubMods) {
      if (itemRef.deselectionSubMods.length) {
        const modId = itemRef.deselectionSubMods[0]._id;
        const mod = app.modList.get(modId);
        parts.push(`deselection subMod: ${mod.displayName()}`);
      } else {
        parts.push('deselection subMod ✘');
      }
    }
    if (itemRef.mustBeAlwaysSent) {
      parts.push('always send');
    }
    return parts.join('; ');
  },

  updateItemEventListeners() {
    this.stopListening();
    const itemList = this.collection.itemList;
    if (!itemList || !itemList.size()) {
      return;
    }
    const self = this;
    _.each(this.get('items'), (itemRef) => {
      const item = itemList.get(itemRef._id);
      if (item) {
        self.listenTo(item, 'destroy', self._itemWasDestroyed);
      }
    });
  },

  _itemWasDestroyed(item) {
    const itemRefs = _.filter(this.get('items'), (itemRef) => {
      return itemRef._id !== item.id;
    });
    if (itemRefs.length !== _.size(this.get('items'))) {
      this.set('items', itemRefs);
    }
  },

  detailsViewClassForListField(/* field */) {
    return null;
  },

  hasItemWithId(itemId) {
    return _.any(this.get('items'), (itemRef) => {
      return itemRef._id === itemId;
    });
  },

  getVisibleInPlaces() {
    return [];
  },

  isSynced() {
    return this.hasArr('integrations');
  },

  getPosIdSummary() {
    if (this.get('posId') === this.get('i9nId')) {
      return this.get('posId');
    }

    const posSystem = this.get('integrations')[0].system;
    switch (posSystem) {
      case IntegrationSystem.Omnivore:
        return `posId: ${this.get('posId')}, omnivoreId: ${this.get('i9nId')}`;
      case IntegrationSystem.Olo:
        return `POS ID: ${this.get('posId')}, OlO ID: ${this.get('i9nId')}`;
      default:
        return this.get('posId');
    }
  },

  addItems(itemIds, success) {
    if (this.isNew()) {
      const list = [].concat(this.get('items') || []);
      itemIds.forEach((itemId) => {
        const item = this.collection.itemList.get(itemId);
        if (item) {
          list.push({ _id: itemId });
        }
      });
      this.set('items', list);
      this.updateItemEventListeners();
      if (success) {
        success();
      }
    } else {
      app.postRequest(
        `${this.url()}/add-items`,
        {
          itemIds,
        },
        (data) => {
          this.set(this.parse({ data }));
          this.updateItemEventListeners();
          if (success) {
            success();
          }
        },
      );
    }
  },

  moveItem(oldIndex, newIndex, success) {
    if (this.isNew()) {
      if (
        this.get('items').length > newIndex &&
        this.get('items').length > oldIndex &&
        oldIndex >= 0 &&
        newIndex >= 0
      ) {
        const list = [].concat(this.get('items'));
        app.JsonHelper.swap(list, oldIndex, newIndex);
        this.set('items', list);
        if (success) {
          success();
        }
      } else {
        Log.warn('no such item');
        // Maybe this is happening because the UI is not refreshed; refresh it
        this.set('__ignore__', Math.random());
      }
    } else {
      const self = this;
      const itemId = self.get('items')[oldIndex]._id;
      app.postRequest(
        `${this.url()}/move-item`,
        {
          itemId,
          position: newIndex,
        },
        (data) => {
          self.set(self.parse({ data }));
          if (success) {
            success();
          }
        },
      );
    }
  },

  removeItem(itemId, success, error) {
    const self = this;
    if (this.isNew()) {
      if (this.hasItemWithId(itemId)) {
        const itemRefs = _.filter(this.get('items'), (itemRef) => {
          return itemRef._id !== itemId;
        });
        this.set('items', itemRefs);
      } else {
        Log.warn('no such item');
        // Maybe this is happening because the UI is not refreshed; refresh it
        this.set('__ignore__', Math.random());
      }
      if (success) {
        success();
      }
    } else {
      app.postRequest(
        `${this.url()}/remove-item`,
        {
          itemId,
        },
        (data) => {
          self.set(self.parse({ data }));
          if (success) {
            success();
          }
        },
        error,
      );
    }
  },

  updateItem(itemId, itemRef, success, error) {
    const self = this;
    if (this.isNew()) {
      this._setItem(itemId, itemRef);
      if (success) {
        success();
      }
    } else {
      app.postRequest(
        `${this.url()}/update-item`,
        {
          itemId,
          item: itemRef,
        },
        (data) => {
          self._setItem(itemId, data.item);
          if (success) {
            success(self, data.item);
          }
        },
        error,
      );
    }
  },

  toggleAllMods(shown, callback) {
    const self = this;
    async.forEachSeries(
      this.get('items'),
      (itemRef, cb) => {
        if (shown && itemRef.hideFromMenu) {
          delete itemRef.hideFromMenu;
        } else if (!shown) {
          itemRef.hideFromMenu = true;
        } else {
          async.setImmediate(cb);
          return;
        }

        if (self.isNew()) {
          self._setItem(itemRef._id, itemRef);
          async.setImmediate(cb);
        } else {
          app.postRequest(
            `${self.url()}/update-item`,
            {
              itemId: itemRef._id,
              item: itemRef,
            },
            (data) => {
              self._setItem(itemRef._id, data.item);
              cb();
            },
            cb,
          );
        }
      },
      callback,
    );
  },

  _setItem(itemId, itemRef) {
    for (let i = 0; i < this.get('items').length; i++) {
      if (this.get('items')[i]._id === itemId) {
        const items = [].concat(this.get('items'));
        items[i] = itemRef;
        this.set('items', items);
        this.updateItemEventListeners();
      }
    }
  },
});

app.MenuItemArray.updateAll = (modGroups, itemId, itemRef) => {
  async.forEachSeries(
    modGroups,
    (modGroup, callback) => {
      modGroup.updateItem(
        itemId,
        {
          _id: itemId,
          ...itemRef,
        },
        () => {
          callback(null);
        },
        (err) => {
          callback(err);
        },
      );
    },
    (err) => {
      if (err) {
        Log.error(err);
      }
    },
  );
};
