import Backbone from 'backbone';
import _ from 'underscore';

import { KioskHelper } from '@biteinc/business-rules';
import { Log } from '@biteinc/common';
import {
  IntegrationSystem,
  KioskAvailability,
  KioskBatteryState,
  KioskBluetoothState,
  KioskBluetoothStateHelper,
  KioskDeviceType,
  KioskDeviceTypeHelper,
  KioskNetworkInterface,
  KioskNetworkInterfaceHelper,
  KioskNotificationType,
  KioskNotificationTypeHelper,
  KioskOs,
  KioskPaymentTerminalModel,
  KioskPaymentTerminalModelHelper,
  KioskPaymentTerminalState,
  KioskPaymentTerminalStateHelper,
  KioskPeripheralConnectionState,
  KioskPeripheralType,
  KioskPeripheralTypeHelper,
  KioskPusherState,
  KioskPusherStateHelper,
  KioskWifiPowerState,
  KioskWifiPowerStateHelper,
  PrinterType,
  PrinterTypeHelper,
} from '@biteinc/enums';
import { PaymentHelper, StringHelper, Time } from '@biteinc/helpers';

import { getProcessingNameFromNotificationType } from '../helpers/kiosk_notification_helper';
import { TimeHelper } from '../helpers/time_helper';
import { template } from '../template';
import { kioskIconUrlFromType, printerIconUrlFromType } from './kiosk_status_helper';

function emojiSpan(emoji, content) {
  return `<span emoji="${emoji}">${content}</span>`;
}

function iconSpan(iconClass, content) {
  return `<span class="icon ${iconClass}">${content}</span>`;
}

function paymentSystemRequiresBluetooth(system) {
  return system === IntegrationSystem.SquareReaderDeprecated;
}

function peripheralTemplate(className, name) {
  return (
    // prettier-ignore
    `<div class="section peripheral ${className}">` +
      '<div class="short">' +
        '<div class="header">' +
          '<div class="device-icon"></div>' +
          '<div class="device-identifier">' +
            '<div class="device-name">' +
              `<span class="name-span">${name}</span>` +
            '</div>' +
          '</div>' +
          '<div class="device-light"></div>' +
        '</div>' +
      '</div>' +
      '<div class="long">' +
        '<div class="errors"></div>' +
        '<div class="warnings"></div>' +
        '<div class="details"></div>' +
        '<div class="fineprint"></div>' +
        '<div class="fineprint-extra">' +
          '<div class="toggle"><span class="title"></span></div>' +
          '<div class="details"></div>' +
        '</div>' +
      '</div>' +
    '</div>'
  );
}

const DeviceInfoStruct = function DeviceInfoStruct(name, isInventory) {
  this.name = name;
  this.subtitle = null;
  this.iconUrl = null;
  this.isInventory = !!isInventory;
  this.errors = [];
  this.warnings = [];
  this.menuIsInUse = false;
  this.details = [];
  this.fineprint = [];
  this.extras = [];
  this.shortfineprint = [];
};
DeviceInfoStruct.prototype.addWarning = function addWarning(text, className, silent) {
  this.warnings.push({
    text,
    className,
    silent,
  });
};

app.KioskStatusView = Backbone.View.extend({
  _expanded: false,
  template: template(
    // prettier-ignore
    `<div class="kiosk-status">` +
        `<div class="status-row">` +
          `<div class="section ipad">` +
            `<div class="short">` +
              `<div class="header">` +
                `<div class="device-icon"></div>` +
                `<div class="device-identifier">` +
                  `<div class="device-name">` +
                    `<span class="name-span"></span>` +
                    `<span class="in-use-badge"></span>` +
                  `</div>` +
                `</div>` +
                `<div class="device-light"></div>` +
                `<div class="btn-group on-off"></div>` +
                `<div class="timestamp"></div>` +
                `<div class="dropdown actions">` +
                  `<button type="button" class="btn settings" data-bs-toggle="dropdown" aria-label="Settings">•••</button>` +
                  `<ul class="dropdown-menu collapsed dropdown-menu-right kiosk-actions">` +
                    `<li class="item edit-kiosk"><a>Edit Kiosk ${app.HtmlHelper.resellerRightIcon}</a></li>` +
                  `</ul>` +
                `</div>` +
              `</div>` +
              `<div class="fineprint"></div>` +
            `</div>` +
            `<div class="long">` +
              `<div class="errors"></div>` +
              `<div class="warnings"></div>` +
              `<div class="details"></div>` +
              `<div class="fineprint"></div>` +
              `<div class="fineprint-extra">` +
                `<div class="toggle"><span class="title"></span></div>` +
                `<div class="details"></div>` +
              `</div>` +
            `</div>` +
          `</div>` +
        `</div>` +
        `<div class="status-row">${
          peripheralTemplate('printer', 'Printer')
          }${peripheralTemplate('payment', 'Payment')
        }</div>` +
      `</div>`,
  ),
  turnOffDropdownTemplate: template($('#turnoff-dropdown-template').html()),

  initialize(options) {
    this._kiosk = options.kiosk;
    this._status = options.status;
    this._editKioskCallback = options.editKioskCallback;
  },

  setExpanded(expanded) {
    if (this._expanded === expanded) {
      return;
    }

    this._expanded = expanded;
    if (this._expanded) {
      // Fetch MDM status lazily only once the kiosk cell view gets expanded
      this._kiosk?.fetchMdmStatus();

      this.$('.short .fineprint').slideUp(400, 'easeInOutCubic');
      this.$('.long').slideDown(400, 'easeInOutCubic');
    } else {
      this.$('.short .fineprint').slideDown(400, 'easeInOutCubic');
      this.$('.long').slideUp(400, 'easeInOutCubic');
    }
  },

  _getLocation() {
    if (app.location) {
      return app.location;
    }
    const model = this._kiosk || this._status;
    if (!app.orgList) {
      return app.locationList.get(model.get('locationId'));
    }
    return app.orgList.getLocationById(model.get('locationId'));
  },

  _peerId() {
    if (this._kiosk) {
      return this._kiosk.displayName();
    }
    return this._status.get('peerId') || '';
  },

  _bodyWasClicked() {
    this.setExpanded(!this._expanded);
    if (this._expanded) {
      this.trigger(app.KioskStatusView.Events.ViewWillExpand);
    } else {
      this.trigger(app.KioskStatusView.Events.ViewWillCollapse);
    }
    return false;
  },

  _isVeryOldStatusAge(ageDuration) {
    return ageDuration > 30 * Time.MINUTE;
  },

  _isOldStatusAge(ageDuration) {
    return ageDuration > 5 * Time.MINUTE;
  },

  _classForStatusAge(startTimestamp) {
    const diff = Date.now() - startTimestamp;
    if (this._isVeryOldStatusAge(diff)) {
      return 'very-old';
    }
    if (this._isOldStatusAge(diff)) {
      return 'old';
    }
    // Anything less than 5 minutes.
    return '';
  },

  _updateTimestamp($timestamp) {
    // Bail if the model has been updated and this is a dead view
    if (this._kiosk) {
      if (!this._kiosk.collection) {
        clearTimeout(this._timestampTimer);
        return;
      }
    } else if (!this._status.collection) {
      clearTimeout(this._timestampTimer);
      return;
    }

    const updatedAt = this._status.get('lastStatusReceivedAt') || this._status.get('updatedAt');
    $timestamp.text(`${TimeHelper.timeAgoStrFromNow(updatedAt)} ago`);
    $timestamp.toggleClass(this._classForStatusAge(updatedAt), true);
    $timestamp.attr('title', TimeHelper.format(updatedAt, TimeHelper.FullDateFormat));
    this._timestampTimer = setTimeout(() => {
      this._updateTimestamp($timestamp);
    }, Time.SECOND);
  },

  _generateDeviceStatsString(deviceHasBattery, deviceStatus) {
    const parts = [];

    if (deviceHasBattery) {
      if (deviceStatus.batteryLevel === -1) {
        parts.push(emojiSpan('🔋', 'unknown'));
      } else {
        let levelStr = (deviceStatus.batteryLevel * 100).toFixed(2);
        if (parseInt(levelStr, 10) === parseFloat(levelStr, 10)) {
          levelStr = `${parseInt(levelStr, 10)}`;
        }
        parts.push(emojiSpan('🔋', `${levelStr}%`));
      }
    }

    if (_.has(deviceStatus, 'brightnessLevel')) {
      parts.push(emojiSpan('🔆', `${Math.floor(deviceStatus.brightnessLevel * 100)}%`));
    }

    return parts.join(' | ');
  },

  _generateMenuVersionString(deviceStatus) {
    const parts = [];
    if (deviceStatus.menuTimestamp > 0) {
      parts.push(`Menu Data: ${TimeHelper.format(deviceStatus.menuTimestamp)}`);
    }
    // Don't show gcn/vitrine build versions to customers. We want to release these apps as often
    // as possible without having to explain minor changes to everyone.
    if (app.sessionUser.isBite()) {
      if (deviceStatus.gcnVersionName) {
        const prefix = 'biteinc-gcn.';
        const gcnVersion = deviceStatus.gcnVersionName.startsWith(prefix)
          ? deviceStatus.gcnVersionName.substring(prefix.length)
          : deviceStatus.gcnVersionName;
        parts.push(`gcn v${gcnVersion}`);
      } else if (deviceStatus.vitrineVersionName) {
        const prefix = 'biteinc-vitrine.';
        const gcnVersion = deviceStatus.vitrineVersionName.startsWith(prefix)
          ? deviceStatus.vitrineVersionName.substring(prefix.length)
          : deviceStatus.vitrineVersionName;
        parts.push(`vitrine v${gcnVersion}`);
      } else if (deviceStatus.menuJSVersion > 0) {
        parts.push(`Menu JS: ${TimeHelper.format(deviceStatus.menuJSVersion)}`);
      }
    }
    return parts.join(', ');
  },

  _addDropdownAction($dropdown, title, options) {
    let fullTitle = title;
    if (options.rightIcon) {
      fullTitle += ` ${options.rightIcon}`;
    }

    const $action = $(`<li class="item"><a>${fullTitle}</a></li>`);
    if (options.isCritical) {
      $action.addClass('critical');
    }

    if (options.clickHandler) {
      $action.click(() => {
        if (options.confirmation) {
          options.confirmation(options.clickHandler);
        } else {
          options.clickHandler();
        }
      });
    } else if (options.url) {
      $action.find('a').attr('href', options.url);
      $action.find('a').attr('target', '_blank');
    } else {
      Log.error('bad dropdown options', title);
    }

    $dropdown.append($action);
  },

  _showConfigUpdateModal() {
    const detailsView = new app.BaseDetailsView({
      title: `Send Kiosk Config to ${this._peerId()}`,
      saveButtonTitles: ['Send Config', 'Sending', 'Sent!'],
      model: new app.KioskConfig({}, { kiosk: this._kiosk }),
      schema: app.KioskConfig.Schema(),
      callback(model) {
        Log.debug('got model', model);
        app.showSavedToast('Success!');
      },
    });
    app.modalManager.showModalWithView(detailsView);
  },

  _addDropdownActionForNotification($dropdown, notificationType, options) {
    const self = this;
    let onClickHandler = function onClickHandler() {
      if (
        !self._status.has('pusherState') ||
        self._status.get('pusherState') !== KioskPusherState.Subscribed
      ) {
        const shouldProceed = confirm(
          `This kiosk isn't connected to Pusher so it won't receive this notification. Do you want to proceed anyway?`,
        );
        if (!shouldProceed) {
          return;
        }
      }

      let data = null;
      if (KioskNotificationType.TestPrint === notificationType) {
        const message = prompt('Please enter a test message.', 'Test Message');
        if (!(message || '').length) {
          return;
        }
        data = {
          message,
          destination: 0,
        };
      }

      if (KioskNotificationType.UpdatePaymentTerminal === notificationType) {
        if (app.location.hasIntegrationWithSystem(IntegrationSystem.Heartland)) {
          const terminalId = prompt(
            "Please enter the kiosk's terminalId. Optional. BE VERY CAREFUL.",
            '',
          );
          if (!_.isString(terminalId)) {
            return;
          }
          const updateParams = {};
          if (terminalId.trim().length) {
            updateParams.terminalId = terminalId.trim();
          }
          data = {
            updateParams: {
              terminalId: terminalId.trim(),
            },
          };
        } else if (app.location.hasIntegrationWithSystem(IntegrationSystem.FreedomPayKiosk)) {
          const palFileUrl = prompt('Please enter the PAL package file URL', '');
          if (!palFileUrl) {
            return;
          }
          if (!StringHelper.isUrl(palFileUrl)) {
            alert('Invalid URL');
            onClickHandler();
            return;
          }
          data = {
            updateParams: {
              palFileUrl,
            },
          };
        }
      }

      if (KioskNotificationType.JawsLicenseUpdate === notificationType) {
        const licenseUrl = prompt('Please enter the Jaws license file URL', '');
        if (!licenseUrl) {
          return;
        }
        if (!StringHelper.isUrl(licenseUrl)) {
          alert('Invalid URL');
          onClickHandler();
          return;
        }
        data = { licenseUrl };
      }

      app.showSavedToast(getProcessingNameFromNotificationType(notificationType));

      const model = self._kiosk || self._status;
      model.notifyDevice(notificationType, data, {
        error() {
          app.showSavedToast('Failed. :(');
        },
        success() {
          switch (notificationType) {
            case KioskNotificationType.UpdatePaymentTerminal:
            case KioskNotificationType.EnableAsam:
            case KioskNotificationType.DisableAsam:
            case KioskNotificationType.RequestStatus:
              app.showSavedToastAndReload('Success! Reloading in 10sec.', true, 10000);
              break;
            default:
              app.showSavedToast('Success!');
          }
        },
      });
    };

    switch (notificationType) {
      case KioskNotificationType.ConfigUpdate:
        onClickHandler = self._showConfigUpdateModal.bind(this);
        break;
    }

    return this._addDropdownAction($dropdown, KioskNotificationTypeHelper.name(notificationType), {
      isCritical:
        notificationType === KioskNotificationType.RestartApp ||
        notificationType === KioskNotificationType.AppLogout,
      clickHandler: onClickHandler,
      ...(options || {}),
    });
  },

  _updateAvailabilityState(state, message) {
    app.showSavedToast(message);
    this._kiosk.save(
      {
        availability: state,
      },
      {
        patch: true,
        error() {
          app.showSavedToast('Failed. :(');
        },
        success() {
          app.showSavedToastAndReload('Success! Reloading in 10sec.', true, 10000);
        },
      },
    );
  },

  _removeDropdownActionsEditKiosk() {
    const $ipad = this.$('.ipad');
    $ipad.find('.dropdown.actions .dropdown-menu .edit-kiosk').remove();
  },

  _cleanupDropdownActions() {
    const $ipad = this.$('.ipad');
    const $actionsDropdown = $ipad.find('.dropdown.actions');
    if (!$actionsDropdown.find('.dropdown-menu').children().length) {
      $actionsDropdown.remove();
    }
  },

  _renderOnOffButton() {
    const self = this;

    if (this._kiosk.get('availability') === KioskAvailability.ON) {
      const $turnOffDropdown = $(self.turnOffDropdownTemplate());
      $turnOffDropdown.find('.until-next a').click(() => {
        self._updateAvailabilityState(
          KioskAvailability.OFF_UNTIL_NEXT,
          'Turning off until tomorrow...',
        );
        $turnOffDropdown.toggleClass('open');
        return false;
      });
      $turnOffDropdown.find('.indefinitely a').click(() => {
        self._updateAvailabilityState(
          KioskAvailability.OFF_INDEFINITELY,
          'Turning off indefinitely...',
        );
        $turnOffDropdown.toggleClass('open');
        return false;
      });

      self.$('.btn-group.on-off').append($turnOffDropdown);

      if (app.sessionUser.canControlKiosks()) {
        $turnOffDropdown.find('button').click((e) => {
          $turnOffDropdown.toggleClass('open');
          e.stopPropagation();
        });
      } else {
        $turnOffDropdown.find('button').prop('disabled', true);
      }
    } else {
      const $button = $(
        '<button type="button" class="btn btn-secondary btn-sm turn-on">Turn On</button>',
      );

      if (app.sessionUser.canControlKiosks()) {
        $button.click(() => {
          const reqId = $button.initLoadingButton('Turn On', 'Turning On', 'Turned On');
          self._kiosk.save(
            {
              availability: KioskAvailability.ON,
            },
            {
              patch: true,
              error() {
                $button.loadingDidFinishWithError(reqId);
              },
              success() {
                $button.loadingDidFinishSuccessfully(reqId);
              },
            },
          );
          return false;
        });
      } else {
        $button.prop('disabled', true);
      }

      self.$('.btn-group.on-off').append($button);
    }
  },

  _renderDevice($el, info) {
    const nonSilentLineCountByKey = {};
    let hasTooltips = false;

    // Errors, warnings, details, fine print
    if (info.iconUrl) {
      $el.find('.device-icon').css('background-image', `url(${info.iconUrl})`);
    }
    $el.find('.device-name span.name-span').text(info.name);
    if (info.menuIsInUse) {
      $el.find('.device-name span.in-use-badge').text('in use');
    } else {
      $el.find('.device-name span.in-use-badge').remove();
    }
    if (info.subtitle) {
      $el.find('.device-name').append(`<span class="subtitle-span">${info.subtitle}</span>`);
    }
    const keysByLocation = {
      short: ['fineprint'],
      long: ['errors', 'warnings', 'details', 'fineprint'],
    };
    _.each(keysByLocation, (keys, location) => {
      _.each(keys, (key) => {
        nonSilentLineCountByKey[key] = 0;
        const lines = info[('short' === location ? 'short' : '') + key];
        _.each(lines, (line) => {
          const $span = $('<span></span>');
          if (_.isObject(line)) {
            if (line.className) {
              $span.addClass(line.className);
            }
            $span.html(line.text);
            if (line.tooltip) {
              hasTooltips = true;
              $span.append(app.renderTooltip(line.tooltip));
            }
            if (!line.silent) {
              nonSilentLineCountByKey[key]++;
            }
          } else {
            nonSilentLineCountByKey[key]++;
            $span.html(line);
          }
          $el.find(`.${location}>.${key}`).append($span);
        });
      });
    });

    // Overall status dot.
    if (nonSilentLineCountByKey.errors > 0) {
      $el.find('.device-name span.name-span').toggleClass('errors', true);
    } else if (nonSilentLineCountByKey.warnings > 0) {
      $el.find('.device-name span.name-span').toggleClass('warnings', true);
    } else if (!info.isInventory) {
      $el.find('.device-name span.name-span').toggleClass('good', true);
    }

    // Extras
    $el.find('.fineprint-extra .toggle .title').text(info.extraTitle);
    if (info.extras.length) {
      const $extraList = $el.find('.fineprint-extra .details');
      _.each(info.extras, (extraLine) => {
        $extraList.append(`<span>${extraLine}</span>`);
      });
      $extraList.hide();

      const $arrow = $('<span class="arrow">▼</span>');
      const $extraToggle = $el.find('.fineprint-extra .toggle');
      $extraToggle.append($arrow);
      $extraToggle.click(() => {
        if ($extraList.is(':visible')) {
          $extraList.slideUp();
          $arrow.css('transform', 'rotate(0deg)');
        } else {
          $extraList.slideDown();
          $arrow.css('transform', 'rotate(180deg)');
        }
        return false;
      });
      $extraToggle.find('.title').toggleClass('has-extras', true);
    }

    if (hasTooltips) {
      app.activateTooltips($el);
    }
  },

  _renderPrinterStatus($el, printerStatus, expectedMacAddress) {
    const info = new DeviceInfoStruct(`Printer`);
    if (this._kiosk) {
      const printerType = this._kiosk.get('printerType');
      info.iconUrl = printerIconUrlFromType(printerType);
    }

    if ((expectedMacAddress || '').length) {
      if (!printerStatus || printerStatus.macAddress !== expectedMacAddress) {
        info.warnings.push(`Expected: ${expectedMacAddress}`);
      }
    }

    if (printerStatus) {
      // Connectivity
      const isConnected =
        KioskPeripheralConnectionState.CONNECTED === printerStatus.connectionState;
      const connectivity = {
        className: 'connected-dot',
        text: `${isConnected ? '' : 'dis'}connected`,
      };
      if (isConnected) {
        connectivity.className += ' online';
        info.details.push(connectivity);
      } else {
        connectivity.className += ' offline';
        info.errors.push(connectivity);
      }
      if ((printerStatus.disconnectedSince || 0) > 0) {
        info.errors.push(
          `No stable connection since ${TimeHelper.format(printerStatus.disconnectedSince)}`,
        );
      }

      // Stability
      if ((printerStatus.consecutiveFailedJobCount || 0) > 0) {
        const failCount = printerStatus.consecutiveFailedJobCount;
        info.errors.push(`${failCount} consecutive fail${failCount === 1 ? '' : 's'}`);
      }

      // Paper
      if (1 === printerStatus.paperState) {
        info.warnings.push('Paper Low');
      } else if (2 === printerStatus.paperState) {
        info.errors.push('Paper Empty');
      }

      // Cover
      if (1 === printerStatus.coverState) {
        info.errors.push('Cover Open');
      }

      info.details.push(printerStatus.macAddress);
    } else if (this._kiosk?.get('printerType')) {
      info.errors.push('Printer is misconfigured on the kiosk');
      info.name = `Invalid ${info.name}`;
      $el.toggleClass('no-status', true);
    } else {
      info.name = `No ${info.name}`;
      $el.toggleClass('no-status', true);
    }

    this._renderDevice($el, info);
  },

  _renderPrinterInventory($el, model) {
    const info = new DeviceInfoStruct('Printer', true);

    if (model.get('printerType') === PrinterType.NoPrinter || !model.get('printerType')) {
      info.name = 'No Printer';
    } else {
      info.iconUrl = printerIconUrlFromType(model.get('printerType'));
      info.fineprint.push(`Type: ${PrinterTypeHelper.name(model.get('printerType'))}`);
      info.fineprint.push(`MAC Address: ${model.get('printerMacAddress')}`);
      info.fineprint.push(`Serial: ${model.get('printerSerialNumber')}`);
    }

    this._renderDevice($el, info);
  },

  _renderPaymentStatus($el, paymentStatus, visibleBluetoothAccessories) {
    const info = new DeviceInfoStruct('Payment');
    if (paymentStatus || this._kiosk) {
      const currentModel = (paymentStatus || {}).type || KioskPaymentTerminalModel.None;
      if (currentModel !== KioskPaymentTerminalModel.None) {
        info.subtitle = `${KioskPaymentTerminalModelHelper.name(currentModel)}`;
      }
      info.iconUrl = KioskPaymentTerminalModelHelper.iconUrl(
        paymentStatus ? paymentStatus.type : this._kiosk.get('paymentTerminalModel'),
      );
    }

    if (this._kiosk) {
      const expectedModel = this._kiosk.get('paymentTerminalModel');
      const currentModel = (paymentStatus || {}).type || KioskPaymentTerminalModel.None;
      if (expectedModel !== currentModel) {
        info.warnings.push(`Expected: ${KioskPaymentTerminalModelHelper.name(expectedModel)}`);
      }
    }

    if (paymentStatus) {
      const stateLine = {
        className: 'connected-dot',
        text: KioskPaymentTerminalStateHelper.name(paymentStatus.state),
      };
      switch (paymentStatus.state) {
        case KioskPaymentTerminalState.READY:
        case KioskPaymentTerminalState.TRANSACTION:
          stateLine.className += ' online';
          info.details.push(stateLine);
          break;
        case KioskPaymentTerminalState.UPDATING:
          stateLine.className += ' warning';
          info.warnings.push(stateLine);
          break;
        default:
          stateLine.className += ' offline';
          info.errors.push(stateLine);
          break;
      }

      if ((paymentStatus.updatedAt || 0) > 0) {
        info.details.push(`Updated on: ${TimeHelper.format(paymentStatus.updatedAt)}`);
      }
      if ((paymentStatus.disconnectedSince || 0) > 0) {
        info.errors.push(
          `No stable connection since ${TimeHelper.format(paymentStatus.disconnectedSince)}`,
        );
      }
    } else {
      info.name = `No ${info.name}`;
      $el.toggleClass('no-status', true);
    }

    const kioskPaymentSystem = app.location?.getKioskPaymentI9nSchema()?.system;
    if (app.location && paymentSystemRequiresBluetooth(kioskPaymentSystem)) {
      if (_.size(visibleBluetoothAccessories)) {
        info.extraTitle = 'see visible bluetooth devices';
        info.extras = _.map(visibleBluetoothAccessories, (accessory) => {
          return `${accessory.name} (${accessory.modelNumber}): ${
            accessory.isConnected ? '✅' : '🔴'
          }`;
        });
      } else {
        info.extraTitle = 'no visible bluetooth devices';
      }
    }

    this._renderDevice($el, info);
  },

  _renderPaymentInventory($el, model) {
    const info = new DeviceInfoStruct('Payment', true);

    if (model.get('paymentTerminalModel')) {
      info.iconUrl = KioskPaymentTerminalModelHelper.iconUrl(model.get('paymentTerminalModel'));
      info.subtitle = KioskPaymentTerminalModelHelper.name(model.get('paymentTerminalModel'));
      if (model.hasStr('paymentTerminalSerialNumber')) {
        info.fineprint.push(`Serial: ${model.get('paymentTerminalSerialNumber')}`);
      }
    } else {
      info.name = 'No Payment';
    }

    this._renderDevice($el, info);
  },

  _renderAdditionalPeripheral(className, name) {
    let $row = this.$('.status-row.appendix').last();
    if (!$row.length || $row.children().length === 2) {
      $row = $('<div class="status-row appendix"></div>');
      this.$el.find('.kiosk-status').append($row);
    }

    const $cell = $(peripheralTemplate(className, name));
    $row.append($cell);
    if (!this._expanded) {
      $cell.find('.long').css('display', 'none');
    }
    return $cell;
  },

  _peripheralParamsFromType(peripheralType) {
    const name = KioskPeripheralTypeHelper.name(peripheralType);
    switch (peripheralType) {
      case KioskPeripheralType.Scanner:
        return { className: 'scanner', deviceName: name };
      case KioskPeripheralType.Scale:
        return { className: 'scale', deviceName: name };
      case KioskPeripheralType.MSR:
        return { className: 'msr', deviceName: name };
      case KioskPeripheralType.HID:
        return { className: 'hid', deviceName: name };
      case KioskPeripheralType.StormAudioNav:
        return { className: 'storm', deviceName: name };
      default:
        Log.error('unsupported peripheral', peripheralType);
        return null;
    }
  },

  _renderPrinterPeripheralStatus(peripheralStatus) {
    const peripheralParams = this._peripheralParamsFromType(peripheralStatus.deviceType);

    const info = new DeviceInfoStruct(peripheralParams.deviceName);
    const isConnected =
      KioskPeripheralConnectionState.CONNECTED === peripheralStatus.connectionState;
    const connectivity = {
      className: 'connected-dot',
      text: `${isConnected ? '' : 'dis'}connected`,
    };
    if (isConnected) {
      connectivity.className += ' online';
      info.details.push(connectivity);
    } else {
      connectivity.className += ' offline';
      info.errors.push(connectivity);
    }
    if ((peripheralStatus.disconnectedSince || 0) > 0) {
      info.errors.push(
        `No stable connection since ${TimeHelper.format(peripheralStatus.disconnectedSince)}`,
      );
    }

    let target = peripheralStatus.target;
    if (target.indexOf('TCPS:') === 0) {
      target = target.substr(5);
    }
    info.details.push(target);

    const $peripheral = this._renderAdditionalPeripheral(
      peripheralParams.className,
      peripheralParams.deviceName,
    );
    this._renderDevice($peripheral, info);
  },

  _renderUsbPeripheralStatus(peripheralStatus, peripheralType) {
    const peripheralParams = this._peripheralParamsFromType(peripheralType);

    const info = new DeviceInfoStruct(peripheralParams.deviceName);
    const isConnected = !!peripheralStatus?.connected;
    const connectivity = {
      className: 'connected-dot',
      text: `${isConnected ? '' : 'dis'}connected`,
    };
    if (isConnected) {
      connectivity.className += ' online';
      info.details.push(connectivity);
    } else {
      connectivity.className += ' offline';
      info.errors.push(connectivity);
    }
    if ((peripheralStatus?.disconnectedSince || 0) > 0) {
      info.errors.push(
        `No stable connection since ${TimeHelper.format(peripheralStatus?.disconnectedSince)}`,
      );
    }
    if (peripheralType === KioskPeripheralType.StormAudioNav) {
      info.details.push(peripheralStatus.isLicensed ? 'Licensed' : 'Unlicensed');
    }

    const $peripheral = this._renderAdditionalPeripheral(
      peripheralParams.className,
      peripheralParams.deviceName,
    );
    this._renderDevice($peripheral, info);
  },

  _renderPeripheralInventory(peripheralInventory) {
    const peripheralParams = this._peripheralParamsFromType(peripheralInventory.peripheralType);
    const info = new DeviceInfoStruct(peripheralParams.deviceName, true);
    info.fineprint.push(`Serial: ${peripheralInventory.serialNumber}`);

    const $peripheral = this._renderAdditionalPeripheral(
      peripheralParams.className,
      peripheralParams.deviceName,
    );
    this._renderDevice($peripheral, info);
  },

  render() {
    this.$el.html(this.template());

    this.$el.off('click');
    this.$el.click(this._bodyWasClicked.bind(this));

    // Used later to toggle nested cells.
    if (this._status) {
      if (this._status.has('kioskId')) {
        this.$el.attr('id', this._status.get('kioskId'));
      } else {
        this.$el.attr('id', this._status.get('peerId').split(' ').join('-'));
      }
    }
    this.$el.addClass('kiosk-card');

    const $ipad = this.$('.ipad');
    const $printer = this.$('.printer');
    const $payment = this.$('.payment');
    const isBite = app.sessionUser.isBite();
    const isBiteSupport = app.sessionUser.isBiteSupport();
    const isBiteEng = app.sessionUser.isBiteEng();
    const location = this._getLocation();
    const timezone = location?.get('timezone') || Time.BITE_TZ;

    const $actionsDropdown = $ipad.find('.dropdown.actions');
    $actionsDropdown.click((e) => {
      $ipad.find('.dropdown.actions').toggleClass('open');
      e.stopPropagation();
    });
    if (this._editKioskCallback && app.location) {
      $actionsDropdown.find('.edit-kiosk').click(() => {
        this._editKioskCallback();
      });
    } else {
      $actionsDropdown.find('.edit-kiosk').remove();
    }

    if (this._kiosk && app.location) {
      this._renderOnOffButton();
    }
    if (!this._expanded) {
      this.$('.long').css('display', 'none');
    }

    let deviceIdentifierHtml = '';

    if (!app.sessionUser.canManageKiosks()) {
      this._removeDropdownActionsEditKiosk();
    }

    if (this._status) {
      this.$el.addClass(`status-${this._status.id}`);
      const deviceStatus = this._status.get('deviceStatus');

      if (this._timestampTimer) {
        clearTimeout(this._timestampTimer);
      }

      const info = new DeviceInfoStruct(this._peerId());

      let kioskDeviceType = this._kiosk?.get('deviceType');
      if (!kioskDeviceType) {
        // kioskDeviceType will be missing if this is an untracked device
        // i.e. someone logged into the native app with their bite admin credentials
        // We only allow this on Elo androids and iOS device
        if (deviceStatus.os === KioskOs.Android) {
          kioskDeviceType = KioskDeviceType.AndroidElo22;
        }
        kioskDeviceType = KioskDeviceType.iPadPro;
      }

      const { displayModelName, statusSummary, warnings } = KioskHelper.parseDeviceStatus(
        deviceStatus,
        kioskDeviceType,
      );
      info.iconUrl = kioskIconUrlFromType(
        KioskHelper.deviceTypeFromModelName(displayModelName, kioskDeviceType),
      );

      this._updateTimestamp($ipad.find('.timestamp'));
      const diff =
        Date.now() - (this._status.get('lastStatusReceivedAt') || this._status.get('updatedAt'));
      if (this._isVeryOldStatusAge(diff)) {
        info.errors.push('Kiosk has not reported status in a long time.');
      } else if (this._isOldStatusAge(diff)) {
        info.warnings.push('Kiosk has not reported status recently.');
      }

      if (3 === deviceStatus.availability) {
        info.errors.push('Starting Up');
      } else if (2 === deviceStatus.availability) {
        let ms = 'In Maintenance Mode';
        if (deviceStatus.downSince) {
          ms += ' Since ';
          const downSince = Time.moment(deviceStatus.downSince, timezone);
          if (downSince.isBefore(TimeHelper.localMoment().startOf('day'))) {
            ms += `on ${downSince.format('MM/DD/YY')} at `;
          }
          ms += downSince.format('HH:mm');
        }
        info.errors.push(ms);
      } else if (1 === deviceStatus.availability) {
        let turnedOffAtStr = 'Turned Off';
        if (this._kiosk && this._kiosk.get('availability')) {
          if (this._kiosk.get('availability') === KioskAvailability.OFF_UNTIL_NEXT) {
            turnedOffAtStr += ' (Until Next Service)';
          } else {
            turnedOffAtStr += ' (Indefinitely)';
          }
        }
        if (deviceStatus.turnedOffAt) {
          const turnedOffAt = Time.moment(deviceStatus.turnedOffAt, timezone);
          if (turnedOffAt.isBefore(TimeHelper.localMoment().startOf('day'))) {
            turnedOffAtStr += ` on ${turnedOffAt.format('MM/DD/YY')}`;
          }
          turnedOffAtStr += ` at ${turnedOffAt.format('HH:mm')}`;
        }
        info.warnings.push(turnedOffAtStr);
      }

      if (this._status.get('isUsingMenu')) {
        info.menuIsInUse = true;
      } else {
        const paymentTerminalStatus = this._status.get('paymentTerminalStatus');
        if (KioskPaymentTerminalState.TRANSACTION === paymentTerminalStatus?.state) {
          info.errors.push('Stuck in Transaction State!');
        }
      }

      if (isBite) {
        if (this._kiosk && _.keys(this._kiosk.get('knownIssues')).length) {
          let issues = `${app.HtmlHelper.biteRightIcon} Known Issues:`;
          _.each(this._kiosk.get('knownIssues'), (issue, issueIdStr) => {
            issues += `<br />${app.Kiosk.nameFromKnownIssue(parseInt(issueIdStr, 10))}`;
            if ((issue.notes || '').trim().length) {
              issues += ` (${issue.notes})`;
            }
          });
          info.shortfineprint.push(issues);
          info.fineprint.push(issues);
        }

        if (this._kiosk && this._kiosk.hasStr('notes')) {
          const notes = `${app.HtmlHelper.biteRightIcon} Notes: ${this._kiosk.get('notes')}`;
          info.shortfineprint.push(notes);
          info.fineprint.push(notes);
        }
      }
      if (this._kiosk) {
        info.fineprint.push(`S/N: ${this._kiosk.get('serialNumber')}`);
      }
      const { externalIpAddress } = this._status.get('deviceStatus');
      if (app.location) {
        if (this._kiosk?.get('internalName')) {
          info.fineprint.push(this._kiosk?.get('internalName'));
        }
        info.fineprint.push(statusSummary);
        if (externalIpAddress) {
          info.fineprint.push(`IP Address: ${externalIpAddress}`);
        }
      } else {
        if (this._kiosk?.get('internalName')) {
          deviceIdentifierHtml += `<span class="subtitle">${this._kiosk?.get('internalName')}</span><br />`;
        }
        deviceIdentifierHtml += `<span class="subtitle">${statusSummary}</span>`;
        if (externalIpAddress && isBite) {
          deviceIdentifierHtml += `<br /><span class="subtitle">IP Address: ${externalIpAddress}</span>`;
        }
      }
      if (warnings.length && isBite) {
        info.warnings.push(
          ...warnings.map((warning) => {
            return warning.message;
          }),
        );
      }
      const menuVersionString = this._generateMenuVersionString(deviceStatus);
      if (menuVersionString.length) {
        info.fineprint.push(menuVersionString);
      }

      if (isBite && this._kiosk) {
        const mdmStatus = this._kiosk.getMdmStatusSummary();
        if (mdmStatus.length) {
          info.fineprint.push(`${app.HtmlHelper.biteRightIcon} ${mdmStatus}`);
        }
      }

      info.details.push(`Uptime: ${TimeHelper.roughDurationFromTimestamp(deviceStatus.uptime)}`);
      info.details.push(this._generateDeviceStatsString(this._status.isIOS(), deviceStatus));

      // Communications stats.
      const communicationsParts = [];

      // Reachability
      const networkInterface = KioskNetworkInterfaceHelper.stringFromEnum(
        deviceStatus.currentNetworkInterface,
      );
      if (deviceStatus.online) {
        const networkParts = [networkInterface];
        if (deviceStatus.ipAddress) {
          networkParts.push(deviceStatus.ipAddress);
        }
        communicationsParts.push(emojiSpan('🌐', `online (${networkParts.join('; ')})`));
      } else {
        info.addWarning(emojiSpan('🌐', `offline (${networkInterface})`), null, true);
      }

      // Wifi
      if (_.has(deviceStatus, 'wifiPowerState')) {
        const wifiOn = deviceStatus.wifiPowerState === KioskWifiPowerState.On;
        const wifiSsid = deviceStatus.wifiSsid || '';
        let wifiStateStr = KioskWifiPowerStateHelper.name(deviceStatus.wifiPowerState);
        if (wifiOn) {
          const wifiEmoji = deviceStatus.wifiConnected ? '✅' : '🔴';
          wifiStateStr += ` ${emojiSpan(wifiEmoji, wifiSsid.length ? `(${wifiSsid})` : '')}`;
        }
        wifiStateStr = iconSpan('wifi', wifiStateStr);
        if (!wifiOn) {
          info.addWarning(wifiStateStr, null, true);
        } else {
          communicationsParts.push(wifiStateStr);
        }
      }

      const kioskPaymentSystem = app.location?.getKioskPaymentI9nSchema()?.system;

      // Bluetooth
      if (_.has(deviceStatus, 'bluetoothState')) {
        const btState = deviceStatus.bluetoothState;
        const btStateStr = iconSpan('bluetooth', KioskBluetoothStateHelper.name(btState));
        if (
          btState !== KioskBluetoothState.PoweredOn &&
          app.location &&
          paymentSystemRequiresBluetooth(kioskPaymentSystem)
        ) {
          info.errors.push(btStateStr);
        } else {
          communicationsParts.push(btStateStr);
        }
      }

      if (communicationsParts.length) {
        info.details.push(communicationsParts.join(' | '));
      }

      const enrollmentCount = deviceStatus.enrollmentCount || 0;
      if (enrollmentCount > 0) {
        info.warnings.push(
          `${enrollmentCount} unfinished enrollment${enrollmentCount === 1 ? '' : 's'}`,
        );
      }

      if (this._status.has('pusherState')) {
        const pusherState = this._status.get('pusherState');
        const pusherStr = `Pusher: ${KioskPusherStateHelper.name(pusherState)}`;
        if (pusherState !== KioskPusherState.Subscribed) {
          info.errors.push({
            text: pusherStr,
            tooltip: {
              text: 'This kiosk will not receive any notifications including when the day part changes.\nPlease, check your network settings.',
              isWarning: true,
            },
          });
        } else {
          info.details.push(pusherStr);
        }
      }

      if (isBite && this._status.isIOS()) {
        const asam =
          `${app.HtmlHelper.biteRightIcon} ASAM ${deviceStatus.isAsamEnabled ? 'On' : 'OFF'}` +
          ` (System: ${deviceStatus.isSystemAsamEnabled ? 'On' : 'OFF'})`;
        const asamIsGood =
          !location.isLive() || (deviceStatus.isAsamEnabled && deviceStatus.isSystemAsamEnabled);
        if (asamIsGood) {
          info.details.push(asam);
        } else {
          info.warnings.push(asam);
        }
      }

      if (deviceStatus.isVoiceOverEnabled) {
        if (this._status.get('isUsingMenu')) {
          info.warnings.push('VoiceOver Enabled');
        } else {
          info.errors.push('VoiceOver Enabled');
        }
      }
      if (app.locationSettings?.get('adaEnabled') && !deviceStatus.isHeadsetPluggedIn) {
        info.errors.push('Headset Disconnected');
      }

      if (deviceStatus.batteryState === KioskBatteryState.UNPLUGGED) {
        info.errors.push('A/C Unplugged');
      }

      if (
        deviceStatus.currentNetworkInterface === KioskNetworkInterface.WiFi &&
        deviceStatus.expectedNetworkInterface === KioskNetworkInterface.Ethernet
      ) {
        info.warnings.push('Ethernet Disconnected');
      }

      if (this._status.isIOS()) {
        if (deviceStatus.notificationsAuthorizationStatus !== 2) {
          let notificationStatus = 'Not determined';
          if (deviceStatus.notificationsAuthorizationStatus === 1) {
            notificationStatus = 'Denied';
          }
          notificationStatus = `Notifications authorization: ${notificationStatus}`;
          info.addWarning(notificationStatus);
        }
        if (deviceStatus.cameraAuthorizationStatus !== 3) {
          let cameraStatus = 'Not determined';
          if (deviceStatus.cameraAuthorizationStatus === 2) {
            cameraStatus = 'Denied';
          } else if (deviceStatus.cameraAuthorizationStatus === 1) {
            cameraStatus = 'Restricted';
          }
          cameraStatus = `Camera authorization: ${cameraStatus}`;
          info.addWarning(cameraStatus, null, !location.get('usesDelphi'));
        }
      }
      if (this._kiosk && this._status.get('prefix') !== this._kiosk.get('prefix')) {
        info.warnings.push(
          `Prefix mismatch: ${this._status.get('prefix')} vs ${this._kiosk.get('prefix')}.`,
        );
      }

      const expectedPrinterMacAddress =
        this._kiosk && this._kiosk.get('printerType') ? this._kiosk.get('printerMacAddress') : null;
      if (
        _.size(deviceStatus.visiblePrinterMacAddresses) ||
        _.size(deviceStatus.visibleUsbPeripherals)
      ) {
        info.extraTitle = 'see visible printers & peripherals';
        info.extras = [];

        // Printers
        _.map(deviceStatus.visiblePrinterMacAddresses, (macAddress) => {
          if (macAddress === (expectedPrinterMacAddress || '')) {
            // eslint-disable-next-line no-param-reassign
            macAddress += ' (expected)';
          }

          info.extras.push(macAddress);
        });

        // Printer peripherals
        _.each(deviceStatus.visiblePrinterPeripherals, (peripheral) => {
          if (peripheral.indexOf('TCPS:') === 0) {
            // eslint-disable-next-line no-param-reassign
            peripheral = peripheral.substr(5);
          }
          info.extras.push(peripheral);
        });

        _.each(deviceStatus.visibleUsbPeripherals, (peripheral) => {
          info.extras.push(peripheral);
        });
      } else {
        info.extraTitle = 'no visible printers';
      }

      this._renderDevice($ipad, info);

      // Peripherals
      const receiptPrinterStatus = this._status.get('kioskPrinterStatus');
      this._renderPrinterStatus($printer, receiptPrinterStatus, expectedPrinterMacAddress);
      this._renderPaymentStatus(
        $payment,
        this._status.get('paymentTerminalStatus'),
        deviceStatus.visibleBluetoothAccessories,
      );

      if (receiptPrinterStatus) {
        _.each(receiptPrinterStatus.peripheralStati, (peripheralStatus) => {
          this._renderPrinterPeripheralStatus(peripheralStatus);
        });
      }

      if (this._kiosk ? this._kiosk.get('hasHid') : this._status.get('hidStatus')) {
        this._renderUsbPeripheralStatus(this._status.get('hidStatus'), KioskPeripheralType.HID);
      }
      if (this._kiosk ? this._kiosk.get('hasMsr') : this._status.get('msrStatus')) {
        this._renderUsbPeripheralStatus(this._status.get('msrStatus'), KioskPeripheralType.MSR);
      }
      if (this._kiosk ? this._kiosk.get('hasScanner') : this._status.get('scannerStatus')) {
        this._renderUsbPeripheralStatus(
          this._status.get('scannerStatus'),
          KioskPeripheralType.Scanner,
        );
      }
      if (this._kiosk ? this._kiosk.get('hasScale') : this._status.get('scaleStatus')) {
        this._renderUsbPeripheralStatus(this._status.get('scaleStatus'), KioskPeripheralType.Scale);
      }
      if (this._status.has('jawsStatus')) {
        this._renderUsbPeripheralStatus(
          this._status.get('jawsStatus'),
          KioskPeripheralType.StormAudioNav,
        );
      }

      // Dropdown actions.
      const $dropdown = $ipad.find('.actions .dropdown-menu');
      const canReceivePusherNotifications = this._status.canReceivePusherNotifications();
      const usesSimpleMdm = this._kiosk?.isIOS() && this._status;

      if (canReceivePusherNotifications) {
        if (isBiteSupport) {
          this._addDropdownActionForNotification(
            $dropdown,
            KioskNotificationType.RequestScreenshot,
            {
              rightIcon: app.HtmlHelper.biteRightIcon,
            },
          );
        }

        if (app.sessionUser.canManageKiosks()) {
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.TestPrint, {
            rightIcon: app.HtmlHelper.resellerRightIcon,
          });
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.MenuUpdate, {
            rightIcon: app.HtmlHelper.resellerRightIcon,
          });
        }
      }

      if (isBite && !this._kiosk) {
        this._addDropdownAction($dropdown, 'Delete This Status', {
          isCritical: true,
          rightIcon: app.HtmlHelper.biteRightIcon,
          clickHandler: () => {
            this._status.destroy({
              url: `${this._status.collection.url()}/${this._status.get('peerId')}`,
              wait: true,
            });
          },
          confirmation: (clickHandler) => {
            const msg = `Are you sure you want to delete all statuses from ${this._status.displayName()}?`;
            if (window.confirm(msg)) {
              clickHandler();
            }
          },
        });
      }

      if (app.sessionUser.canManageKiosks()) {
        $dropdown.append('<hr /><li class="item header"><span>Advanced</span></li>');
      }

      if (
        this._kiosk &&
        (isBite ||
          (app.sessionUser.canManageKiosks() &&
            KioskDeviceTypeHelper.mdmIsAccessibleByNonBiteUsers(this._kiosk.get('deviceType'))))
      ) {
        this._addDropdownAction($dropdown, 'Open MDM ↗', {
          clickHandler: () => {
            app.showSavedToast('Redirecting momentarily!');
            this._kiosk.getMdmUrl((err, url) => {
              if (!err) {
                window.open(url);
              }
            });
          },
          rightIcon: KioskDeviceTypeHelper.mdmIsAccessibleByNonBiteUsers(
            this._kiosk.get('deviceType'),
          )
            ? app.HtmlHelper.resellerRightIcon
            : app.HtmlHelper.biteRightIcon,
        });
        if (KioskDeviceTypeHelper.mdmSupportsFetchingRemoteUrl(this._kiosk.get('deviceType'))) {
          this._addDropdownAction($dropdown, 'Open Remote Access ↗', {
            clickHandler: () => {
              app.showSavedToast('Redirecting momentarily!');
              this._kiosk.getMdmRemoteAccessUrl((err, url) => {
                if (!err) {
                  window.open(url);
                }
              });
            },
            rightIcon: KioskDeviceTypeHelper.mdmIsAccessibleByNonBiteUsers(
              this._kiosk.get('deviceType'),
            )
              ? app.HtmlHelper.resellerRightIcon
              : app.HtmlHelper.biteRightIcon,
          });
        }
      }

      if (canReceivePusherNotifications) {
        if (isBiteSupport) {
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.RequestLogs, {
            rightIcon: app.HtmlHelper.biteRightIcon,
          });
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.RequestStatus, {
            rightIcon: app.HtmlHelper.biteRightIcon,
          });
        }

        if (app.sessionUser.canManageKiosks() && !this._status.isIOS()) {
          this._addDropdownActionForNotification(
            $dropdown,
            KioskNotificationType.RunWhitelistTest,
            {
              rightIcon: app.HtmlHelper.resellerRightIcon,
            },
          );
        }

        if (isBiteSupport && usesSimpleMdm) {
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.AppUpdate, {
            rightIcon: app.HtmlHelper.biteRightIcon,
          });
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.EnableAsam, {
            rightIcon: app.HtmlHelper.biteRightIcon,
          });
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.DisableAsam, {
            rightIcon: app.HtmlHelper.biteRightIcon,
          });
        }

        if (
          isBiteSupport &&
          this._kiosk &&
          (KioskDeviceTypeHelper.usesEloView(this._kiosk.get('deviceType')) ||
            KioskDeviceTypeHelper.usesWorkspaceOneMdm(this._kiosk.get('deviceType')))
        ) {
          this._addDropdownActionForNotification(
            $dropdown,
            KioskNotificationType.JawsLicenseUpdate,
            {
              rightIcon: app.HtmlHelper.biteRightIcon,
            },
          );
        }

        if (app.location) {
          // Garcon based kiosk will always pull latest config on exit and restart
          if (isBiteEng && (this._status.isIOS() || this._status.isAndroid())) {
            this._addDropdownActionForNotification($dropdown, KioskNotificationType.ConfigUpdate, {
              rightIcon: app.HtmlHelper.biteRightIcon,
            });
          }

          if (
            isBiteSupport &&
            kioskPaymentSystem &&
            PaymentHelper.systemSupportsUpdatePushes(kioskPaymentSystem)
          ) {
            this._addDropdownActionForNotification(
              $dropdown,
              KioskNotificationType.UpdatePaymentTerminal,
              {
                rightIcon: app.HtmlHelper.biteRightIcon,
              },
            );
          }

          if (
            isBiteSupport &&
            (kioskPaymentSystem === IntegrationSystem.FreedomPayKiosk ||
              kioskPaymentSystem === IntegrationSystem.ParPay ||
              kioskPaymentSystem === IntegrationSystem.AurusPay)
          ) {
            this._addDropdownActionForNotification(
              $dropdown,
              KioskNotificationType.UploadPaymentTerminalLogs,
              {
                rightIcon: app.HtmlHelper.biteRightIcon,
              },
            );
          }
        }
      }

      if (isBiteSupport && usesSimpleMdm) {
        this._addDropdownAction($dropdown, 'Move MDM Group ASAM', {
          clickHandler: () => {
            app.showSavedToast('Moving');
            this._kiosk.moveDeviceToMDMGroupASAM(true);
          },
          rightIcon: app.HtmlHelper.biteRightIcon,
        });
        this._addDropdownAction($dropdown, 'Move MDM Group SAM', {
          clickHandler: () => {
            app.showSavedToast('Moving');
            this._kiosk.moveDeviceToMDMGroupASAM(false);
          },
          rightIcon: app.HtmlHelper.biteRightIcon,
        });
      }

      if (app.sessionUser.canControlKiosks()) {
        if (
          app.location?.getKioskPaymentI9nSchema()?.apiPayment?.canUpdateTerminalIdleText &&
          this._kiosk.attributes.paymentTerminalModel !== KioskPaymentTerminalModel.None
        ) {
          this._addDropdownAction($dropdown, 'Update Payment Terminal Idle Text', {
            clickHandler: () => {
              this._kiosk.updatePaymentTerminalIdleText();
            },
            confirmation: (clickHandler) => {
              const msg = `Are you sure you want to update the idle text for ${this._kiosk.displayName()}?`;
              if (window.confirm(msg)) {
                clickHandler();
              }
            },
          });
        }
        // not dry
        this._addDropdownActionForNotification($dropdown, KioskNotificationType.RestartApp, {
          confirmation: (clickHandler) => {
            let msg = `Are you sure you want to restart the app on ${this._peerId()}?`;
            if (usesSimpleMdm && !this._status.get('deviceStatus').isAsamEnabled) {
              msg = `${this._peerId()} is not in ASAM!!!\nIt won't auto-restart\n${msg}`;
            }
            if (window.confirm(msg)) {
              clickHandler();
            }
          },
        });
        if (KioskHelper.kioskSupportsAppLogout(this._status.get('deviceStatus'))) {
          this._addDropdownActionForNotification($dropdown, KioskNotificationType.AppLogout, {
            confirmation: (clickHandler) => {
              let msg = `Are you sure you want to log out of the app on ${this._peerId()}? Once logged out, the app will no longer be online.`;
              if (window.confirm(msg)) {
                clickHandler();
              }
            },
          });
        }
        if (usesSimpleMdm || !this._status.isIOS()) {
          this._addDropdownAction($dropdown, 'Restart Device', {
            isCritical: true,
            clickHandler: () => {
              this._kiosk.restartDeviceThroughMDM({
                success: () => {
                  app.showSavedToast('Restart command sent!');
                },
              });
            },
            confirmation: (clickHandler) => {
              const msg = `Are you sure you want to restart the device ${this._kiosk.displayName()}?`;
              if (window.confirm(msg)) {
                clickHandler();
              }
            },
          });
        }
      }
    } else {
      $ipad.find('.timestamp').text('No status');
      $ipad.toggleClass('no-status', true);
      $printer.toggleClass('no-status', true);
      $payment.toggleClass('no-status', true);

      $ipad.find('.btn-group.on-off').remove();

      const deviceInfo = new DeviceInfoStruct(
        this._kiosk.get('name') || `Serial: ${this._kiosk.get('serialNumber')}`,
        true,
      );
      deviceInfo.iconUrl = kioskIconUrlFromType(this._kiosk.get('deviceType'));
      if (this._kiosk.has('deviceType')) {
        deviceInfo.fineprint.push(KioskDeviceTypeHelper.name(this._kiosk.get('deviceType')));
      }
      this._renderDevice($ipad, deviceInfo);
      this._renderPrinterInventory($printer, this._kiosk);
      this._renderPaymentInventory($payment, this._kiosk);

      _.each(this._kiosk.get('peripherals'), (peripheralInventory) => {
        this._renderPeripheralInventory(peripheralInventory);
      });
    }

    if (deviceIdentifierHtml.length) {
      $ipad.find('.device-identifier').append(deviceIdentifierHtml);
      $ipad.find('.device-name').toggleClass('multi-line', true);
      $ipad.find('.device-icon').toggleClass('multi-line', true);
    }

    this._cleanupDropdownActions();

    return this;
  },
});
app.KioskStatusView.Events = {
  ViewWillExpand: 'viewWillExpand',
  ViewWillCollapse: 'viewWillCollapse',
};
