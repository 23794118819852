import _ from 'underscore';

import { Validators } from '@biteinc/common';
import { CreateUserType, ModelType, UserRight } from '@biteinc/enums';
import { userSchema } from '@biteinc/schemas';

app.User = app.AbstractModel.extend({
  ModelName: 'user',
  Schema: userSchema,
  Type: ModelType.User,

  defaults() {
    if (app.location) {
      return {
        orgId: app.org.id,
        locationGroupId: app.singleLocationGroup.id,
      };
    }
    if (app.org) {
      return {
        orgId: app.org.id,
      };
    }
    return {};
  },

  canBeDestroyed() {
    if (!app.AbstractModel.prototype.canBeDestroyed.apply(this)) {
      return false;
    }

    // Don't allow deleting yourself
    if (this.id === app.sessionUser.id) {
      return false;
    }

    // Don't allow deleting users at the location scope since this will delete the user for the org.
    // If a user is to be removed from a location, then their location access should be updated.
    if (app.location) {
      return false;
    }

    return true;
  },

  displayName() {
    let name = this.id;
    if (this.hasStr('username')) {
      name = this.get('username');
    }
    if (this.hasStr('firstName') || this.hasStr('lastName')) {
      name = `${this.get('firstName') || ''} ${this.get('lastName') || ''}`.trim();
    }
    if (this.hasStr('internalName')) {
      name += ` (${this.get('internalName')})`;
    }
    if (!this.get('registered')) {
      name += ' [Unregistered]';
    } else if (this.get('disabled')) {
      name += ' [Suspended]';
    }
    return name;
  },

  getMembership(orgId) {
    if (this.isBite()) {
      return this.get('biteMembership');
    }

    const phiOrgId = orgId || app.org.id;

    return this.getOrgMemberships().find((orgMembership) => {
      return orgMembership.orgId.toString() === phiOrgId.toString();
    });
  },

  getAccessCode() {
    return this.getMembership().accessCode;
  },

  hasRight(right) {
    return this.hasSomeRight([right]);
  },

  hasSomeRight(rights) {
    const userRights = this.getMembership().rights;
    const matchingRights = _.intersection(rights, userRights);

    return matchingRights.length;
  },

  getMemberships() {
    return this.isBite() ? [this.get('biteMembership')] : this.getOrgMemberships();
  },

  getOrgMemberships() {
    return this.get('orgMemberships') ?? [];
  },

  isApiToken() {
    return !!this.hasRight(UserRight.ApiToken);
  },

  isBiteEng() {
    return this.hasRight(UserRight.BiteEng);
  },

  isBiteOps() {
    return this.hasRight(UserRight.BiteOps);
  },

  isBiteSupport() {
    return this.hasRight(UserRight.BiteSupport);
  },

  isBite() {
    return !!this.get('biteMembership');
  },

  isAdmin() {
    return this.hasRight(UserRight.Admin);
  },

  isOrgWide() {
    return this.isBite() || this.get('isOrgWide');
  },

  isSiteWide() {
    if (this.isBite()) {
      return true;
    }

    const orgMembership = this.getMembership();
    const userLocationIdsArray = orgMembership.locationIds;
    const UserLocationIdsSet = new Set(userLocationIdsArray);

    const siteLocationIds = app.site.get('locationIds');

    return siteLocationIds.every((siteLocationId) => {
      return UserLocationIdsSet.has(siteLocationId);
    });
  },

  canControlKiosks() {
    return this.hasRight(UserRight.ControlKiosk);
  },

  canViewKiosks() {
    return this.canControlKiosks();
  },

  canViewOrders() {
    return this.hasRight(UserRight.ViewOrders);
  },

  canRefundOrders() {
    return this.hasRight(UserRight.RefundOrders);
  },

  canAccessAllVendors() {
    return this.hasRight(UserRight.AllVendorAccess);
  },

  canViewMenuLibrary() {
    return this.hasRight(UserRight.ViewMenuLibrary);
  },

  canViewLocationGroups() {
    return this.hasSomeRight([UserRight.Reporting, UserRight.ManageLowerUsers]);
  },

  canGenerateReports() {
    return this.hasRight(UserRight.Reporting);
  },

  can86Items() {
    return this.hasRight(UserRight.Items86);
  },

  canManageMenu() {
    return this.hasRight(UserRight.ManageMenu);
  },

  canAccessPosSync() {
    return this.canAccessAllVendors() && this.canManageMenu();
  },

  canManageUsers() {
    return this.hasRight(app.org ? UserRight.ManageLowerUsers : UserRight.ManageLowerBiteUsers);
  },

  canManageMenuCovers() {
    return this.canAccessAllVendors() && this.hasRight(UserRight.ManageMenuCovers);
  },

  canManageDiscounts() {
    return this.hasRight(UserRight.ManageDiscounts);
  },

  canEditAppearance() {
    return this.hasRight(UserRight.EditMenuAppearance);
  },

  canManageChannelSettings() {
    return this.hasRight(UserRight.ManageChannelSettings);
  },

  canManageFlashSettings() {
    return (
      this.canManageChannelSettings() ||
      this.hasRight(UserRight.ShowFlashClosedScreen) ||
      this.hasRight(UserRight.ToggleDiningOptions)
    );
  },

  canManageKioskSettings() {
    return this.canManageChannelSettings() || this.hasRight(UserRight.ToggleDiningOptions);
  },

  canManageBiteLift() {
    return this.hasRight(UserRight.ManageBiteLift);
  },

  canManageQuiz() {
    return this.hasRight(UserRight.BiteSupport);
  },

  canManageOrderThrottling() {
    return this.hasRight(UserRight.ManageOrderThrottling);
  },

  canManageCustomers() {
    return this.hasRight(UserRight.ManageCustomers);
  },

  canManageWebSettings() {
    return this.hasRight(UserRight.ManageWebSettings);
  },

  canManageCateringSettings() {
    return this.hasRight(UserRight.ManageCateringSettings);
  },

  canViewActivities() {
    return this.hasRight(UserRight.ViewActivities);
  },

  canManageSomeI9ns() {
    return this.hasRight(UserRight.ManageI9nLite);
  },

  canDeleteCustomers() {
    return this.hasRight(UserRight.DeleteCustomers);
  },

  canManageOrgSettings() {
    return (
      this.isBite() ||
      this.hasSomeRight([
        UserRight.ManageBrandColorAndLogo,
        UserRight.ManageThirdPartyPrivacyPolicy,
      ])
    );
  },

  canMagicCopyLocation() {
    return this.hasRight(UserRight.MagicCopyLocation);
  },

  canManageChangesets() {
    return this.canMagicCopyLocation();
  },

  canCreateSitesAndChannels() {
    return this.hasRight(UserRight.CreateSitesAndChannels);
  },

  canManageFullI9ns() {
    return this.hasRight(UserRight.ManageI9n);
  },

  canViewSystemLogs() {
    return this.hasRight(UserRight.ViewSystemLogs);
  },

  canManageKiosks() {
    return this.hasRight(UserRight.ManageKiosks);
  },

  canAccessSomeCommonSettings() {
    return this.canManageChannelSettings() || this.canMagicCopyLocation();
  },

  canViewVendors() {
    return (
      this.canViewMenuLibrary() ||
      this.canManageDiscounts() ||
      this.canAccessSomeCommonSettings() ||
      this.canManageFlashSettings() ||
      this.canManageBiteLift() ||
      this.canManageSomeI9ns()
    );
  },

  changePassword(params, callback) {
    if (app.org) {
      params.orgId = app.org.id;
    }
    app.postRequest(
      `/api/users/${this.id}/change-password`,
      params,
      () => {
        callback();
      },
      callback,
    );
  },

  fieldIsPermanent(field /* subProperty */) {
    if (app.AbstractModel.prototype.fieldIsPermanent.apply(this, arguments)) {
      return true;
    }

    // If the user is editing themselves, some fields cannot be changed.
    const isUserSelf = this.id === app.sessionUser.id;
    const fieldsThatArePermanentForSelf = ['userRoleId', 'disabled'];

    if (isUserSelf && fieldsThatArePermanentForSelf.includes(field)) {
      return true;
    }

    return false;
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'locationGroupId':
        return app.LocationGroupList.newSelectableList(app.locationGroupList, {
          includeOrgWide: true,
          includeAllLive: true,
          includeSingleLocationGroups: true,
        });
      case 'userRoleId':
        return app.userRoleList;
      case 'type':
        // This field is shown during the guided location creation flow.
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CreateUserType,
          nameGenerator: (value) => {
            switch (value) {
              case CreateUserType.InStore:
                return 'In-Store';
              case CreateUserType.BiteAdminPortal:
                return 'Bite Admin Portal';
            }
          },
        });
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'locationGroupId': {
        const locationGroup = element;
        return locationGroup.displayNameAsListFieldElement(plainTextOnly);
      }
    }
    return displayName;
  },

  validateField(field, value, callback) {
    switch (field) {
      case 'username':
        // short circuit; you can't have an email that's less than 6 characters
        if (value.length < 6) {
          callback(true);
          return true;
        }
        if (!Validators.isEmail(value)) {
          callback(false, 'Please enter a valid email address');
          return false;
        }

        app.makeRequestWithOptions({
          method: 'POST',
          url: '/api/users/validate-username',
          showAlertOnError: false,
          data: {
            username: value,
            ...(app.org && { orgId: app.org.id }),
          },
          onSuccess() {
            callback(true);
          },
          onFailure(err) {
            callback(false, err);
          },
        });
        break;
      case 'password': {
        const passwordIsValid = value.match(Validators.passwordValidationRegex) !== null;
        if (!passwordIsValid) {
          callback(false, userSchema.fields.password.tooltip?.text);
          return false;
        }
        callback(true);
        break;
      }
      case 'accessCode': {
        const cleanAccessCode = value.replace(/\D/g, '');
        if (cleanAccessCode !== value || cleanAccessCode.length !== 4) {
          callback(false, 'Access code must be 4 digits long.');
          return false;
        }

        if (!this.isNew() && this.getAccessCode() === cleanAccessCode) {
          callback(true);
          return true;
        }

        app.makeRequestWithOptions({
          method: 'POST',
          url: '/api/users/validate-access-code',
          showAlertOnError: false,
          data: {
            accessCode: value,
            userId: this.id,
            ...(app.org && { orgId: app.org.id }),
          },
          onSuccess() {
            callback(true);
          },
          onFailure(err) {
            callback(false, err);
          },
        });
        break;
      }
      default: {
        callback(true);
      }
    }
    return true;
  },

  detailsViewClassForListField(/* field */) {
    return null;
  },

  destroy(opts) {
    app.AbstractModel.prototype.destroy.apply(this, [
      _.extend(opts || {}, {
        url: this.url() + (app.org ? `/orgs/${app.org.id}` : ''),
      }),
    ]);
  },

  url() {
    if (this.isNew()) {
      return '/api/v2/users';
    }

    return `/api/users/${this.id}`;
  },

  save(attrs, opts) {
    if (this.isNew()) {
      if (attrs.usernames) {
        // The user enters multiple usernames as a single string, but the API expects an array.
        attrs.usernames = attrs.usernames
          .split('\n')
          .flatMap((username) => username.split(',').map((u) => u.trim()));
      }
    }

    app.AbstractModel.prototype.save.apply(this, [attrs, opts]);
  },
});
