import _ from 'underscore';

import { MenuRules } from '@biteinc/business-rules';
import { Log } from '@biteinc/common';
import { ModelType } from '@biteinc/enums';

import { KeyCode } from '../../enums/key_code';
import { template } from '../../template';

app.MenuItemArrayDetailsView = app.BaseDetailsView.extend({
  template: template($('#menu-item-array-details-template').html()),

  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.model.collection.itemList, 'change', this._onItemListChange);

    if (!this.model.collection.itemList.hasBeenFetched()) {
      // We don't need to fetch it because we always fetch all menu lists
      this.listenToOnce(this.model.collection.itemList, 'reset', () => {
        this.render();
      });
    }
  },

  getTitle() {
    let title = app.BaseDetailsView.prototype.getTitle.apply(this);
    if (this.model.get('variationSource')) {
      title += ' Variation';
    }
    return title;
  },

  modelDidChange(model) {
    // Preserve some of the unsaved values even if items changed
    if (!_.without(_.keys(model.changed), 'items').length) {
      this.tempValue = this.fieldGroupView.getValue();
      this.tempValue.items = model.get('items');
    }
    app.BaseDetailsView.prototype.modelDidChange.apply(this, arguments);
  },

  viewWasAddedToDom() {
    app.BaseDetailsView.prototype.viewWasAddedToDom.apply(this);
    $(document).on('keyup.menuItemArrayDetailsView', (e) => {
      if (e.ctrlKey && KeyCode.n === e.keyCode) {
        this.$el.find('button.new-item').trigger('click');
        return false;
      }
      if (
        e.ctrlKey &&
        KeyCode.h === e.keyCode &&
        this.fieldGroupView.fieldViewsByField.hideFromMenu
      ) {
        this.fieldGroupView.fieldViewsByField.hideFromMenu.setValue(true);
        this.superView.footer.simulateSaveButtonClick();
        return false;
      }
      if (
        e.ctrlKey &&
        KeyCode.d === e.keyCode &&
        this.fieldGroupView.fieldViewsByField.deemphasize
      ) {
        this.fieldGroupView.fieldViewsByField.deemphasize.setValue(true);
        this.superView.footer.simulateSaveButtonClick();
        return false;
      }

      return true;
    });
  },

  viewWasRemovedFromDom() {
    app.BaseDetailsView.prototype.viewWasRemovedFromDom.apply(this);
    $(document).off('keyup.menuItemArrayDetailsView');
  },

  getDeleteConfirmation() {
    const source = this.model.isModGroup() ? 'menu items' : 'menu pages';
    return `Deleting this ${this.getSchema().displayName} will remove it from all ${source}.`;
  },

  clearState() {
    app.BaseDetailsView.prototype.clearState.apply(this, arguments);
    this.stopListening();
    if (this.dropdown) {
      this.dropdown.destroy();
      this.dropdown = null;
    }
    if (this.categoryDropdownFieldView) {
      this.categoryDropdownFieldView.destroy();
      this.categoryDropdownFieldView = null;
    }
  },

  _hasUnsavedChanges() {
    let hasUnsavedChanges = app.BaseDetailsView.prototype._hasUnsavedChanges.apply(this, arguments);
    if (!hasUnsavedChanges) {
      hasUnsavedChanges = this.model.isNew() && this.model.hasArr('items');
    }
    return hasUnsavedChanges;
  },

  _onItemListChange() {
    this.render();
  },

  _updateItemSummary() {
    const itemRefs = this.model.get('items') || [];
    if (itemRefs.length) {
      this.$el.find('.card-footer').show();
      let summary = `${itemRefs.length} ${this.model.isModGroup() ? 'modifier' : 'item'}${
        itemRefs.length === 1 ? '' : 's'
      }`;
      let imagelessItemCount = 0;
      _.each(itemRefs, (itemRef) => {
        const item = this.model.collection.itemList.get(itemRef._id);
        if (item && item.isMissingImages()) {
          imagelessItemCount++;
        }
      });
      if (imagelessItemCount) {
        summary += ` | ${imagelessItemCount}`;
        summary +=
          '&nbsp;<img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png"' +
          ' srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos">';
      }
      this.$el.find('.card-footer').html(summary);
    } else {
      this.$el.find('.card-footer').hide();
    }
  },

  _updateFieldsThatDependOnMinSelection(minSelectableField) {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    ['deemphasize', 'useAsComboBuilder'].forEach((fieldName) => {
      const fieldView = fieldViews[fieldName];
      if (!fieldView) {
        return;
      }

      const { selectedByDefaultModCount, mustBeAlwaysSentModCount } = this.model
        .get('items')
        .reduce(
          (counts, itemRef) => {
            if (this.model.modIsSelectedByDefault(itemRef._id)) {
              counts.selectedByDefaultModCount++;
            }
            if (this.model.modMustBeAlwaysSent(itemRef._id)) {
              counts.mustBeAlwaysSentModCount++;
            }
            return counts;
          },
          { selectedByDefaultModCount: 0, mustBeAlwaysSentModCount: 0 },
        );

      const disableFields =
        fieldName === 'deemphasize'
          ? !MenuRules.modGroupCanBeDeemphasized(
              minSelectableField.getValue(),
              mustBeAlwaysSentModCount,
              selectedByDefaultModCount,
            )
          : minSelectableField.getValue() > 0;

      fieldView.schema.isReadOnly = disableFields;
      const currentValue = fieldView.getValue();
      // Reset the value so that the field takes an updated `isReadOnly` value.
      if (disableFields && currentValue) {
        fieldView.setValue(false);
      } else {
        fieldView.setValue(currentValue);
      }
    });
  },

  _toggleAllMods(shown, $button) {
    let reqId = null;
    if ($button) {
      reqId = $button.initLoadingButton(
        $button.html(),
        shown ? 'showing' : 'hiding',
        shown ? 'shown' : 'hidden',
      );
    }
    const self = this;
    this.model.toggleAllMods(shown, (err) => {
      if ($button) {
        if (err) {
          $button.loadingDidFinishWithError(reqId);
        } else {
          $button.loadingDidFinishSuccessfully(reqId);
          self._renderItemList();
        }
      }
    });
  },

  _renderItemList() {
    const self = this;
    const isModGroup = this.model.isModGroup();
    const itemCategory = isModGroup ? 'Modifier' : 'Item';

    if (isModGroup) {
      this.listenTo(
        this.fieldGroupView.fieldViewsByField.minSelectable,
        app.FieldView.Events.FieldDidChangeValue,
        this._updateFieldsThatDependOnMinSelection,
      );
      this._updateFieldsThatDependOnMinSelection(
        this.fieldGroupView.fieldViewsByField.minSelectable,
      );

      const $buttonContainer = this.$el.find('.item-list-panel .right-button-container');
      let $showAllButton = $buttonContainer.find('button.show-all');
      if (!$showAllButton.length) {
        $showAllButton = $(
          '<button type="button" class="btn btn-sm btn-secondary show-all">Show All</button>',
        );
        $buttonContainer.prepend($showAllButton);
      }
      let $hideAllButton = $buttonContainer.find('button.hide-all');
      if (!$hideAllButton.length) {
        $hideAllButton = $(
          '<button type="button" class="btn btn-sm btn-secondary hide-all">Hide All</button>',
        );
        $buttonContainer.prepend($hideAllButton);
      }

      $showAllButton.click(function onClick() {
        self._toggleAllMods(true, $(this));
      });
      $hideAllButton.click(function onClick() {
        self._toggleAllMods(false, $(this));
      });

      $showAllButton.prop('disabled', this.isReadOnly);
      $hideAllButton.prop('disabled', this.isReadOnly);
    }

    const itemList = this.model.collection.itemList;
    if (this.model.getFullVendor().syncsModelsWithType(itemList.model.prototype.Type)) {
      this.$el.find('button.new-item').hide();
    } else {
      this.$el.find('button.new-item').click(() => {
        const detailsView = new app.MenuItemDetailsView({
          collection: itemList,
          array: self.model,
          callback(item) {
            self.model.addItems([item.id]);
          },
        });
        app.modalManager.showModalWithView(detailsView);
      });
    }

    const modelIsSynced = this.model.getFullVendor().syncsModelsWithType(this.model.Type);
    let canMoveItems = true;
    if (modelIsSynced) {
      const i9n = app.location.getFullPosI9n();
      const preserveOrder =
        this.model.Type === ModelType.MenuSection
          ? i9n.get('preserveSectionItemOrder')
          : i9n.get('preserveModGroupModOrder') || i9n.get('modGroupModSortPriority')?.length;
      canMoveItems = !preserveOrder;
    }

    if (canMoveItems) {
      canMoveItems = !this.isReadOnly;
    }

    const $itemPanel = this.$('.item-list-panel');
    if (
      !modelIsSynced ||
      (this.model.Type === ModelType.MenuSection && app.location.supportsCompoundMenuItems())
    ) {
      const $dropdownPanel = $itemPanel.find('.select-panel');
      new app.Dropdown().setupDropdown($dropdownPanel, itemList, {
        useMultiSelect: true,
        validate: (item) => {
          if (item.get('archivedAt')) {
            // If the item is archived, then don't allow it to be added.
            return false;
          }

          if (this.model.hasItemWithId(item.id)) {
            // If the item is already in the list, then we can't add it again.
            return false;
          }

          if (
            this.model.Type === ModelType.MenuSection &&
            modelIsSynced &&
            app.location.supportsCompoundMenuItems() &&
            !item.isCompoundItem()
          ) {
            // If the section is synced, then the only items that can be added are compound items.
            return false;
          }

          if (this.model.get('vendorId') && item.get('vendorId') !== this.model.get('vendorId')) {
            // If the item is from a different vendor of the model, then we can't add it.
            return false;
          }

          return true;
        },
        getDisplayName: (item) => {
          return item.displayNameHtml();
        },
        onAddMultiple: (items) => {
          const itemIds = items.map((item) => item.id);
          this.model.addItems(itemIds, () => {
            app.showSavedToast('Added to the end of the list!');
          });
        },
      });

      const $dropdownToggle = $dropdownPanel.find('button.dropdown-toggle');
      $dropdownToggle.prop('disabled', itemList.size() < 1 || this.isReadOnly);
      $dropdownToggle.find('.text').text(`Select ${itemCategory} to Add:`);
    }

    if (this.dropdown) {
      this.dropdown.destroy();
    }
    this.dropdown = new app.Dropdown();

    const options = {
      getSubtitle(item) {
        return self.model.getItemDescription(item.id);
      },
      onClick(item) {
        let detailsView = null;
        const updatedItem = itemList.get(item.id) || item;

        if (self.model.isModGroup()) {
          const itemArrayItem = app.ItemArrayItem.create(self.model, item);
          detailsView = new app.ItemArrayItemDetailsView({
            saveButtonTitles: ['Update', 'Updating', 'Updated!'],
            model: itemArrayItem,
            item: updatedItem,
            itemList,
            schema: itemArrayItem.options.schema,
            callback() {
              app.showSavedToast('Success!');
            },
            isReadOnly: self.isReadOnly,
            getDisplayName() {
              if (!item.displayNameModRef) {
                return item.displayName();
              }

              return item.displayNameModRef(self.model.getItemRefForId(item.id));
            },
          });
        } else {
          const DetailsView = updatedItem.get('constituentItems')
            ? app.CompoundMenuItemDetailsView
            : app.MenuItemDetailsView;
          detailsView = new DetailsView({
            model: updatedItem,
            collection: itemList,
            isReadOnly: self.isReadOnly,
          });
        }

        app.modalManager.showModalWithView(detailsView);
      },
      canBeRemoved(item) {
        return (
          !self.options.isReadOnly &&
          (!modelIsSynced || (item.Type === ModelType.MenuItem && item.isCompoundItem()))
        );
      },
      onRemove(item, $button) {
        let reqId = null;
        if ($button) {
          reqId = $button.initLoadingButton($button.html(), 'removing');
        }
        self.model.removeItem(
          item.id,
          () => {
            if ($button) {
              $button.loadingDidFinishSuccessfully(reqId);
            }
            app.showSavedToast('Item Removed!');
          },
          () => {
            if ($button) {
              $button.loadingDidFinishWithError(reqId);
            }
          },
        );
      },
      ...(canMoveItems && {
        onMove: (oldIndex, newIndex) => {
          self.model.moveItem(oldIndex, newIndex, () => {
            app.showSavedToast();
          });
        },
      }),
      getDisplayName(itemArrayItem) {
        if (!itemArrayItem.displayNameHtmlModRef) {
          return itemArrayItem.displayNameHtml();
        }

        return itemArrayItem.displayNameHtmlModRef(self.model.getItemRefForId(itemArrayItem.id));
      },
    };

    this.dropdown.setupRows($itemPanel, self.model.get('items'), itemList, options);

    this._updateItemSummary();
  },

  _renderPosIdGroup() {
    if (!this.model.isSynced()) {
      return;
    }

    const $displayGroup = $('<div class="field-group"></div>');
    $displayGroup.append(
      this.valueDisplayTemplate({
        label: 'POS IDs:',
        value: this.model.getPosIdSummary(),
      }),
    );
    this.$el.prepend($displayGroup);
  },

  render() {
    const isModGroup = this.model.isModGroup();
    const itemCategory = isModGroup ? 'Modifier' : 'Item';
    app.BaseDetailsView.prototype.render.apply(this);

    if (!this.model.isNew() && this.canAccessParentModel) {
      const visibleInPlaces = this.model.getVisibleInPlaces();
      const withTemplate = template($('#visible-in-template').html());
      const $visibleInSection = $(
        withTemplate({
          labelText: 'Visible in:',
          visibleInHtml: app.HtmlHelper.visibleInHtml(visibleInPlaces),
          canBeRemoved: false,
          canBeAdded: !isModGroup && !visibleInPlaces.length,
          addTitle: 'Page',
        }),
      );

      // Set up Add to Page dropdown menu.
      if (!visibleInPlaces.length) {
        const $moveButton = $visibleInSection.find('.move-item');
        $moveButton.prop('disabled', !app.sessionUser.canManageMenu());
        const allStructures = app.menuStructureList.models;
        const $moveDropdown = $visibleInSection.find('.move-menu');
        _.each(allStructures, (structure) => {
          const pages = structure.getAllPages();
          _.each(pages, (page) => {
            const $pageItem = $(`<li class="item page-item"><a>${page.name}</a></li>`);
            $pageItem.click(() => {
              page.sectionIds.push(this.model.id);
              structure.save(
                {
                  pages: structure.attributes.pages,
                  sectionIds: structure.attributes.sectionIds,
                },
                {
                  error: () => {
                    Log.error('failed to save', this.model, 'in', structure);
                  },
                  success: () => {
                    window.app.showSavedToast();
                    this.render();
                    this.model.trigger('change', this.model);
                  },
                },
              );
            });
            $moveDropdown.append($pageItem);
          });
        });
      }

      const fieldViews = this.fieldGroupView.fieldViewsByField;
      let fieldView = fieldViews.description;
      const hasPosDescriptionAfter =
        fieldView.field === 'description' && this.getSchema().fields.posDescription;
      if (
        this.getSchema().fields[fieldView.field].canHaveLocalizationOverrides &&
        app.locationSettings.get('supportedLanguages').length > 1 &&
        !hasPosDescriptionAfter
      ) {
        const language = _.last(app.locationSettings.get('supportedLanguages'));
        fieldView = fieldViews[`${fieldView.field}_${language}`];
      } else if (hasPosDescriptionAfter) {
        fieldView = fieldViews.posDescription;
      }
      // Fallback to the description field in case the expected description field does not exist.
      fieldView = fieldView ?? fieldViews.description;
      $visibleInSection.insertAfter(fieldView.$el);
    }

    const hasI9n = this.model.getFullVendor().syncsModelsWithType(this.model.Type);
    this.$el.append(
      this.template({
        canAddItems:
          !this.options.isReadOnly &&
          (!hasI9n ||
            (this.model.Type === ModelType.MenuSection &&
              app.location.supportsCompoundMenuItems())),
        canCreateItems: !this.options.isReadOnly && !hasI9n,
        itemCategory,
      }),
    );

    this._renderItemList();

    this._renderPosIdGroup();

    return this;
  },
});
