app.CompoundModGroupList = app.AbstractCollection.extend({
  model: app.CompoundModGroup,

  usesPaginatedFetch() {
    return true;
  },

  url() {
    return '/api/v2/compound-mod-groups';
  },
});
