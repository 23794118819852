import Backbone from 'backbone';

import { Validators } from '@biteinc/common';
import { ApiHeader } from '@biteinc/enums';

import { KeyCode } from '../enums/key_code';
import { template } from '../template';

app.RegisterUserView = Backbone.View.extend({
  initialize() {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.user = new app.User(app.data.userToRegister);
  },

  template: template($('#register-user-template').html()),

  events: {
    'keyup input.first-name': '_onFirstNameKeyUp',
    'keyup input.last-name': '_onLastNameKeyUp',
    'keyup input.access-code': '_onAccessCodeKeyUp',
    'keyup input.password': '_onUsernameKeyUp',
    'keyup input.repeat-password': '_onRepeatPasswordKeyUp',
    'change input.first-name': '_validateInputs',
    'change input.last-name': '_validateInputs',
    'change input.access-code': '_validateInputs',
    'change input.password': '_validateInputs',
    'change input.repeat-password': '_validateInputs',
    'click button.submit': '_submitButtonWasClicked',
  },

  _isFirstNameValid() {
    return this.$firstName.val().trim().length > 0;
  },

  _isLastNameValid() {
    return this.$lastName.val().trim().length > 0;
  },

  _isAccessCodeValid() {
    return true;

    // const accessCodeVal = this.$accessCode.val().trim();

    // // Optional, so a length of 0 is valid
    // return accessCodeVal.length === 0 || accessCodeVal.match(/^\d{4}$/);
  },

  _isPasswordValid() {
    return this.$password.val().match(Validators.passwordValidationRegex) !== null;
  },

  _isRepeatPasswordValid() {
    return this._isPasswordValid() && this.$repeatPassword.val() === this.$password.val();
  },

  _areInputsValid() {
    return (
      this._isFirstNameValid() &&
      this._isLastNameValid() &&
      this._isAccessCodeValid() &&
      (this._isUserRegisteredForSomeOrg() ||
        (this._isPasswordValid() && this._isRepeatPasswordValid()))
    );
  },

  _validateInputs() {
    const isValid = this._areInputsValid();

    this._submitButtonIsDisabled = !isValid;
    this.$('button.submit').prop('disabled', !isValid);
  },

  _onFirstNameKeyUp(e) {
    this._validateInputs();
    if (KeyCode.Enter === e.keyCode && this._isFirstNameValid()) {
      this.$lastName.focus();
    }
  },

  _onLastNameKeyUp(e) {
    this._validateInputs();
    if (KeyCode.Enter === e.keyCode && this._isLastNameValid()) {
      // this.$accessCode.focus();
      this.$password.focus();
    }
  },

  _onAccessCodeKeyUp(e) {
    this._validateInputs();
    if (KeyCode.Enter === e.keyCode && this._isAccessCodeValid()) {
      if (this._isUserRegisteredForSomeOrg()) {
        if (this._areInputsValid()) {
          this._submitButtonWasClicked();
        }
      } else {
        this.$password.focus();
      }
    }
  },

  _onPasswordKeyUp(e) {
    this._validateInputs();
    if (KeyCode.Enter === e.keyCode && this._isPasswordValid()) {
      this.$repeatPassword.focus();
    }
  },

  _onRepeatPasswordKeyUp(e) {
    this._validateInputs();
    if (KeyCode.Enter === e.keyCode && this._areInputsValid()) {
      this._submitButtonWasClicked();
    }
  },

  _submitButtonWasClicked() {
    if (this._submitButtonIsDisabled) {
      return;
    }

    const firstName = this.$firstName.val().trim();
    const lastName = this.$lastName.val().trim();
    // const accessCode = this.$accessCode.val().trim();
    const password = this.$password.val();

    this._submitButtonIsDisabled = true;
    this._adjustAppHeight();

    app.makeRequestWithOptions({
      method: 'POST',
      url: `/api/v2/users/${this.user.id}/register`,
      data: {
        firstName,
        lastName,
        // accessCode,
        password,
      },
      headers: {
        [ApiHeader.OrgId]: this.user.get('orgId'),
      },
      onSuccess: (_response) => {
        this._submitButtonIsDisabled = false;
        window.location = '/';
      },
      onFailure: (error) => {
        this._submitButtonIsDisabled = false;
        this.$message.text(error || '').show();
        this._adjustAppHeight();
      },
    });
  },

  _adjustAppHeight() {
    this.$el.css('padding', '0px');
    $('body').toggleClass('register-user-view', true);
    const winHeight = window.innerHeight || $('body')[0].clientHeight;
    const appHeight = Math.ceil(this.$('.register-user.card').outerHeight()) + 100;

    if (appHeight < winHeight) {
      const margin = (winHeight - appHeight) / 2;
      this.$el.css('padding', `${Math.floor(margin)}px 0`);
    }
  },

  _isUserRegisteredForSomeOrg() {
    return this.user.getMemberships().some((membership) => membership.registered);
  },

  _renderForm() {
    this.$username.val(this.user.get('username'));

    if (this._isUserRegisteredForSomeOrg()) {
      this.$firstName.val(this.user.get('firstName'));
      this.$lastName.val(this.user.get('lastName'));

      // Just because a user is registered, doesn't mean they have these fields set.
      // These fields were optional for legacy users, so if they're not set yet, force the user to
      // set them now.
      // If they're already set, then disable the fields since they likely don't need to be changed.
      if (this.user.get('firstName')) {
        this.$firstName.prop('disabled', true);
      }
      if (this.user.get('lastName')) {
        this.$lastName.prop('disabled', true);
      }

      // All registered users should have a password; hide these fields.
      this.$password.toggle(false);
      this.$repeatPassword.toggle(false);
    }
  },

  _renderMessage() {
    let message;

    if (this.user.isBite()) {
      message = 'Please fill out the form below to finish setting up your account with Bite';
    } else {
      const orgMembership = this.user.getMembership(this.user.get('orgId'));
      const orgName = orgMembership.orgName;

      if (this._isUserRegisteredForSomeOrg()) {
        message = `Please register your Bite account with ${orgName}`;
      } else {
        message = `Please fill out the form below to finish setting up your account with Bite for ${orgName}`;
      }
    }

    this.$message.text(message).toggle(true);
  },

  render() {
    this.$el.html(this.template());
    this.$username = this.$('input.username');
    this.$firstName = this.$('input.first-name');
    this.$lastName = this.$('input.last-name');
    // this.$accessCode = this.$('input.access-code');
    this.$password = this.$('input.password');
    this.$repeatPassword = this.$('input.repeat-password');
    this.$message = this.$('.message');

    this._renderMessage();
    this._renderForm();

    this.$el.css('padding', '0px');

    $(window).on('resize', this._adjustAppHeight.bind(this));
    setTimeout(() => {
      this._adjustAppHeight();
      this.$firstName.focus();
      this._validateInputs();
    }, 1);

    return this;
  },
});
