import _ from 'underscore';

import { I9nSchemaBySystem } from '@biteinc/common';
import {
  IntegrationSystem,
  LanguageCode,
  ModelType,
  OrderChannel,
  OrderChannelHelper,
  OrderPaymentDestination,
  OrderPrintStateHelper,
  OrdersApiVersion,
  OrderSkipReason,
  TransactionResult,
  TransactionState,
  TransactionType,
} from '@biteinc/enums';
import { MathHelper, StringHelper, Time } from '@biteinc/helpers';

import { parseAddressToSingleLine } from '../helpers/address_helper';
import { TimeHelper } from '../helpers/time_helper';

// keep in sync with db order model checkinOrderAbandonedAfter
const checkinOrderAbandonedAfter = Time.HOUR * 2;

app.Order = app.AbstractModel.extend({
  ModelName: 'order',
  Schema: {
    displayName: 'order',
  },
  Type: ModelType.Order,

  initialize() {
    app.AbstractModel.prototype.initialize.apply(this, arguments);

    this._parseTransactions();
    this.listenTo(this, 'change:transactions', this._parseTransactions);
  },

  _parseTransactions() {
    // The API (v2) now returns all transactions associated with the order
    // In Admin, we are really only interested in approved ones.
    this.transactions = _.map(this.get('transactions'), (transactionJson) => {
      return new app.Transaction(transactionJson);
    }).filter((transaction) => {
      return transaction.get('result') === TransactionResult.Approved;
    });
    this.orderedItems = _.map(this.get('orderedItems'), (itemJson) => {
      return new app.AbstractModel(itemJson);
    });
  },

  canCommitTransaction(transaction) {
    // This is the case when a transaction has just gone through authorization
    if (
      transaction.get('state') === TransactionState.Uncommitted &&
      transaction.get('result') === TransactionResult.Approved
    ) {
      return true;
    }
    // This is the case when the commit was declined. So we can try again manually.
    if (
      transaction.get('state') === TransactionState.Uncommitted &&
      transaction.get('result') === TransactionResult.Declined &&
      transaction.has('commitJson')
    ) {
      return true;
    }
    return false;
  },

  canRefundTransaction(transaction) {
    if (this.isCancellable()) {
      return false;
    }
    const details = this._getTransactionRefundDetails(transaction);
    return !details.refunds.length || transaction.getRefundableAmount() > details.refundTotal;
  },

  // Only closed orders, with no refunds that have not been processed yet can show this button
  requiresDisbursement() {
    return (
      this.isClosed() &&
      !this.isPaidAtCashier() &&
      !this.get('allBalanceTransactionsCreated') &&
      !!this.get('transactions').find((transaction) => this.canRefundTransaction(transaction)) &&
      // We only support full refunds for multi-payment manual transfer stripe transactions
      !this.get('refunds')?.length
    );
  },

  // keep in sync between gcn, bureau, and maitred
  getUnrefundedAmountForAllTransactions() {
    return (this.get('refunds') ?? []).reduce((sum, refund) => {
      return sum - refund.total;
    }, this.get('total'));
  },

  // keep in sync between gcn, bureau, and maitred
  canVoidAnyLoyaltyOrCompCardRewards() {
    return this.get('dataForVendors').some((i9nData) => {
      if (!('integrationId' in i9nData)) {
        return false;
      }
      const i9nType = I9nSchemaBySystem[i9nData.system].type;
      // Can't use .includes here because it's not type-safe
      if ('comp-card' !== i9nType && 'loyalty' !== i9nType) {
        return false;
      }
      return this.canVoidLoyaltyOrCompCardRewardsForIntegration(i9nData.integrationId);
    });
  },

  // keep in sync between gcn, bureau, and maitred
  canVoidLoyaltyOrCompCardRewardsForIntegration(integrationId) {
    const i9nData = this._getI9nDataForId(integrationId);
    if (!i9nData?.sentAt || i9nData?.voidedAt) {
      return false;
    }
    return true;
  },

  getUnrefundedAmountForTransaction(transaction) {
    const details = this._getTransactionRefundDetails(transaction);
    return transaction.getRefundableAmount() - details.refundTotal;
  },

  _getTransactionRefundDetails(transaction) {
    const refunds = _.filter(this.get('refunds'), (refund) => {
      return refund.transactionId === transaction.id;
    });
    const refundTotal = _.reduce(
      refunds,
      (total, refund) => {
        return total + refund.total;
      },
      0,
    );
    return { refunds, refundTotal };
  },

  getSendAt(location) {
    if (this.hasToBeSent() || this.isCancelled()) {
      const diningOption = location.getDiningOption(this.get('fulfillmentMethod'));
      return (
        this.get('pickupAt') -
        (diningOption.futureOrdersSendOrderBeforePickupAdvance || 0) * 60 * 1000
      );
    }
    return this.get('wasSentToPos');
  },

  isBiteFutureOrder() {
    return !!this.get('isBiteFutureOrder');
  },

  isOrdersApiV2OrV3() {
    return (
      this.get('ordersApiVersion') === OrdersApiVersion.V2 ||
      this.get('ordersApiVersion') === OrdersApiVersion.V3
    );
  },

  isOrdersApiV3() {
    return this.get('ordersApiVersion') === OrdersApiVersion.V3;
  },

  isClosed() {
    // orders api v2
    return !!this.get('isClosed');
  },

  isCancelled() {
    // orders api v2
    return !!this.get('isCancelled');
  },

  // keep in sync between gcn, bureau, and maitred
  isCancellable() {
    // orders api v2
    if (!this.isOrdersApiV2OrV3()) {
      return false;
    }

    if (this.isCancelled()) {
      // can't double cancel
      return false;
    }

    if (!this.isClosed()) {
      // no need to cancel an unclosed order
      return false;
    }

    if (!this.hasToBeSent()) {
      // too late, it's already been sent. Just refund instead
      return false;
    }

    // do not ensure this is an unsent future order because
    // this might unnecessarily couple future orders and cancel orders

    return true;
  },

  // keep in sync between bureau and maitred
  isAbandonable() {
    // orders api v2
    if (!this.isOrdersApiV3()) {
      return false;
    }

    if (this.isCancelled()) {
      // can't abandon if cancelled
      return false;
    }

    if (this.isClosed()) {
      // can't abandon a closed order
      return false;
    }

    if (!this.hasToBeSent()) {
      // too late, it's already been sent. Just refund instead
      return false;
    }

    // can't abandon if already abandoned
    if (this.isAbandoned()) {
      return false;
    }

    // do not ensure this is an unsent future order because
    // this might unnecessarily couple future orders and cancel orders

    return true;
  },

  // keep in sync between gcn, bureau, and maitred
  isRefundable() {
    if (this.isCancelled()) {
      // there should be no need to refund an order that's already been cancelled
      return false;
    }

    if (!this.isClosed()) {
      // no need to refund an unclosed order
      return false;
    }

    if (this.isCancellable()) {
      // If an order is cancellable, then it has to be cancelled
      return false;
    }

    return (
      this.getUnrefundedAmountForAllTransactions() > 0 || this.canVoidAnyLoyaltyOrCompCardRewards()
    );
  },

  _getCalculatedTotal() {
    return (
      this.get('subTotal') -
      (this.get('discountTotal') || 0) +
      this.get('taxTotal') +
      (this.get('tipTotal') || 0) +
      (this.get('serviceChargeTotal') || 0) +
      (this.get('donationTotal') || 0)
    );
  },

  isFullyDiscounted() {
    return this.get('subTotal') > 0 && this._getCalculatedTotal() === 0;
  },

  // keep in sync between bureau and db-models
  hasEnoughDataForReceipt() {
    if (this.isPaidAtCashier() && this.get('isClosedOrIsFinished')) {
      return true;
    }
    if (this.isFullyDiscounted() && this.get('isClosedOrIsFinished')) {
      return true;
    }
    if (this.get('hasTransactions')) {
      return true;
    }
    return false;
  },

  isCheckinOrder() {
    if (!this.isOrdersApiV2OrV3()) {
      return !!this.get('checkinToken');
    }
    return !!this.get('isCheckinOrder');
  },

  isUncheckedinOrder() {
    return this.isCheckinOrder() && !this.has('checkedInAt');
  },

  isAbandoned() {
    if (!this.isOrdersApiV2OrV3()) {
      // orders api v1
      return !!this.get('isUnfinished');
    }

    if (this.isClosed()) {
      return false;
    }

    return this.get('createdAt') < Date.now() - Time.ORDER_IS_ABANDONED_AFTER;
  },

  isAbandonedUncheckedinOrder() {
    return this.isUncheckedinOrder() && this.get('createdAt') < checkinOrderAbandonedAfter;
  },

  // keep in sync between gcn, bureau, and maitred
  hasToBeSent() {
    if (this.get('wasSentToPos') || this.get('isUnfinished') || this.get('hasFailedToSendToPos')) {
      return false;
    }
    return true;
  },

  failedToSend() {
    if (this.get('hasFailedToSendToPos')) {
      return true;
    }
    return false;
  },

  displayNameHtml() {
    let name = '';

    if (this.isBiteFutureOrder()) {
      let sendLabel = '';
      if (this.isCancelled()) {
        sendLabel = 'Cancelled';
      } else if (this.hasToBeSent()) {
        sendLabel = 'Send';
      } else {
        sendLabel = 'Sent';
      }
      const sendAt = this.getSendAt(app.location);
      const timezone = app.location.get('timezone');
      const sendTimeStr = TimeHelper.displayDateFromTimestamp(sendAt, timezone);
      const pickUpAt = this.get('pickupAt');
      const pickUpTimeStr = TimeHelper.displayDateFromTimestamp(pickUpAt, timezone);
      name += '<span class="badge badge-pill badge-info">Future Order</span><br />';
      if (this.isCancelled()) {
        name += `<span class="badge badge-pill badge-secondary">${sendLabel}</span><br />`;
      } else if (sendAt === pickUpAt) {
        name += `<span class="badge badge-pill badge-secondary">${sendLabel} and Pickup: ${sendTimeStr}</span><br />`;
      } else {
        // only show the send text if a sendAt exists (we may have failed to send)
        name += sendAt
          ? `<span class="badge badge-pill badge-secondary">${sendLabel}: ${sendTimeStr}</span><br />`
          : '<span class="badge badge-pill badge-secondary">Failed to Send</span><br />';
        name += `<span class="badge badge-pill badge-secondary">Pickup: ${pickUpTimeStr}</span><br />`;
      }
    }
    if (this.get('isUnfinished')) {
      name += '<span class="badge badge-pill badge-danger">Unfinished Order</span><br />';
    }
    if (this.get('userDidRejectConsent')) {
      name += 'Facial Rec Consent: Declined<br />';
    }

    if (this.hasStr('clientNumber')) {
      name += `Order: ${this.get('clientNumber')}`;
      const channelDescription = this._getChannelDescription();
      if (channelDescription) {
        name += ` (${channelDescription})`;
      }
      name += '<br />';
    }

    {
      const guestInfo = [];
      if (this.get('guestName')) {
        guestInfo.push(this.get('guestName'));
      }
      if (this.get('guestEmail')) {
        guestInfo.push(this.get('guestEmail'));
      }
      if (this.get('guestPhoneNumber')) {
        guestInfo.push(StringHelper.toFormattedPhoneNumber(this.get('guestPhoneNumber')));
      }
      if (guestInfo.length) {
        name += `Guest: ${guestInfo.join(', ')}<br />`;
      }
    }

    if (this.get('deliveryAddress')) {
      name += `Delivery Address: ${parseAddressToSingleLine(this.get('deliveryAddress'))}<br />`;
    }

    const outpost = this.get('outpost');
    if (outpost?.name) {
      name += `Outpost: ${outpost.name}<br />`;
      if (outpost.roomNumber) {
        name += `Room number: ${outpost.roomNumber} <br />`;
      }
    }

    // Don't show this if it just says the guest placed their order in English.
    const preferences = {
      ...(this.get('preferences') || {}),
    };
    if (preferences['gcn:setting:language'] === LanguageCode.EN_US) {
      delete preferences['gcn:setting:language'];
    }
    if (Object.keys(preferences).length) {
      name += app.HtmlHelper.preFromDict(preferences, 'preferences');
    }
    if (this.has('expoPrintState')) {
      name += `Expo printer: ${OrderPrintStateHelper.name(this.get('expoPrintState'))}`;
    }

    function buildOrderedItemHtml(
      { preText, posCheckId, loyaltyCheckId, fulfillmentCheckId, vendorText, warningText },
      orderedItemsType,
    ) {
      if (posCheckId) {
        name += '<br />';
        name += `POS Check ID: ${posCheckId}`;
      }
      if (fulfillmentCheckId) {
        name += '<br />';
        name += `Fulfillment Check ID: ${fulfillmentCheckId}`;
      }
      if (loyaltyCheckId) {
        name += '<br />';
        name += `Loyalty Check ID: ${loyaltyCheckId}`;
      }
      const vendorTag = vendorText ? ` alt="${vendorText}"` : '';
      const warningTag = warningText ? ` warning="${warningText}"` : '';
      name += `<pre${vendorTag}${warningTag} alt="${orderedItemsType}">${preText.join('\n')}</pre>`;
    }

    // Show the server ordered items by default to accommodate old orders that don't have client
    // ordered items
    name += '<div class="server-ordered-items text-break">';
    _.each(this._getOrderedItemsPreText(this.get('orderedItems')), (orderedItems) => {
      buildOrderedItemHtml(orderedItems, 'Server Ordered Items');
    });
    name += '</div>';

    name += '<div class="client-ordered-items text-break d-none">';
    _.each(this._getOrderedItemsPreText(this.get('clientOrderedItems')), (orderedItems) => {
      buildOrderedItemHtml(orderedItems, 'Client Ordered Items');
    });
    name += '</div>';

    let financials = `Sub: $${MathHelper.displayPrice(this.get('subTotal'))}`;
    if (this.get('discountTotal') >= 0 && this._getDiscountNames().length) {
      financials += `  Discount: $${MathHelper.displayPrice(this.get('discountTotal'))}`;
    }
    if (this.has('taxTotal') && app.locationSettings.get('includeTaxTotalOnReceipts')) {
      financials += `  Tax: $${MathHelper.displayPrice(this.get('taxTotal'))}`;
    }
    if (this.get('tipTotal') > 0) {
      financials += `  Tip: $${MathHelper.displayPrice(this.get('tipTotal'))}`;
    }
    if (this.get('serviceChargeTotal') > 0) {
      financials += `  Service Charge: $${MathHelper.displayPrice(this.get('serviceChargeTotal'))}`;
    }
    if (this.get('donationTotal') > 0) {
      financials += `  Donation: $${MathHelper.displayPrice(this.get('donationTotal'))}`;
    }
    if ((this.has('wasValidated') && this.has('total')) || this.get('total') > 0) {
      financials += `  Grand: $${MathHelper.displayPrice(this.get('total'))}`;
    }
    name += `<pre alt="totals">${financials}</pre>`;

    // Mark the order as "paid at cashier" only if we actually sent it
    if (this.isPaidAtCashier() && this.get('wasSentToPos')) {
      name += '<br />';
      name += '<span class="footer">Paid at Cashier</span>';
    }

    const loyaltySummary = this._getLoyaltySummary();
    if (loyaltySummary) {
      name += `<pre alt="loyalty">${loyaltySummary}</pre>`;
    }

    return name;
  },

  _getChannelDescription() {
    if (this.hasStr('kioskId') && app.kioskList && app.kioskList.get(this.get('kioskId'))) {
      const kiosk = app.kioskList.get(this.get('kioskId'));
      return kiosk.displayName();
    }
    // Only specify while we have kiosk and flash orders living in one location
    if (app.location) {
      // TODO: remove once we break up kiosk and flash
      if (app.location.get('orderChannel') === OrderChannel.Kiosk) {
        return OrderChannelHelper.name(this.get('channel'));
      }
      return '';
    }
    // If we don't have a location, then we should always say which channel the order is from
    return OrderChannelHelper.name(this.get('channel'));
  },

  _getOrderedItemsPreText(orderedItemsToBuildPreTextFor) {
    const ret = [];

    const orderedItemsByVendorName = _.groupBy(orderedItemsToBuildPreTextFor, (orderedItem) => {
      return orderedItem.vendor ? orderedItem.vendor.name : 'no-vendor';
    });
    const vendorNames = _.sortBy(_.keys(orderedItemsByVendorName));
    _.each(vendorNames, (vendorName) => {
      const orderedItemsSameVendor = orderedItemsByVendorName[vendorName];
      const orderedItemsByIntegrationId = _.groupBy(orderedItemsSameVendor, (orderedItem) => {
        return orderedItem.integrationId;
      });
      _.each(orderedItemsByIntegrationId, (orderedItems, integrationId) => {
        const items = _.map(orderedItems, (orderedItem) => {
          let pre = this._preTextLineForItem(orderedItem);
          pre += this._displayMods(orderedItem, 2);
          if (orderedItem.specialRequest) {
            pre += `Special Request: ${orderedItem.specialRequest}\n`;
          }
          return pre;
        });
        let warningText = null;
        let posCheckId = null;
        switch (true) {
          case this.get('sendingToPosWasSkipped') === OrderSkipReason.OfflineDemoMode:
            warningText = 'UNSENT (OFFLINE DEMO MODE)';
            break;
          case this.get('isUnfinished'):
            // orders api v1
            warningText = 'UNFINISHED';
            break;
          case !!orderedItems[0].integrationId &&
            this.wasSentToIntegrationWithId(orderedItems[0].integrationId):
            posCheckId = this.getCheckIdForIntegrationWithId(orderedItems[0].integrationId);
            break;
          case this.isUncheckedinOrder():
            warningText = 'UNSENT (NOT CHECKEDIN)';
            break;
          case this.isAbandonedUncheckedinOrder():
            warningText = 'UNSENT ABANDONED (NOT CHECKEDIN)';
            break;
          case this.isAbandoned():
            warningText = 'ABANDONED';
            break;
          case this.isOrdersApiV2OrV3() && !this.isClosed():
            warningText = 'UNCLOSED';
            break;
          case this.hasToBeSent():
            warningText = 'UNSENT';
            break;
          case this.failedToSend():
            warningText = 'FAILED TO SEND';
            break;
        }

        if (_.size(this.get('refunds'))) {
          const refundedAmount = this.get('refunds').reduce((prevValue, refund) => {
            return prevValue + refund.total;
          }, 0);
          const transactionChargedAmount = this.get('transactions').reduce(
            (prevValue, transaction) => {
              if (
                transaction.type !== TransactionType.Refund &&
                (transaction.result === TransactionResult.Approved ||
                  transaction.result === TransactionResult.PartiallyApproved)
              ) {
                return prevValue + transaction.amount;
              }
              return prevValue;
            },
            0,
          );
          const refundText =
            refundedAmount === transactionChargedAmount ? 'REFUNDED' : 'HAS REFUNDS';

          if (warningText) {
            warningText += ` + ${refundText}`;
          } else {
            warningText = refundText;
          }
        }

        let vendorText = '';
        if (app.location.get('multiVendorSupport')) {
          vendorText = vendorName;
          if (_.keys(orderedItemsByIntegrationId).length > 1) {
            vendorText += ` (${integrationId})`;
          }
        }

        const loyaltyCheckId = this._getLoyaltyCheckId();
        const fulfillmentCheckId = this._getFulfillmentCheckId();

        ret.push({
          preText: items,
          posCheckId,
          loyaltyCheckId,
          fulfillmentCheckId,
          vendorText,
          warningText,
        });
      });
    });
    return ret;
  },

  toCsv() {
    const rows = [];

    if (this.hasStr('clientNumber')) {
      rows.push(['Order number', this.get('clientNumber')]);
      const channelDescription = this._getChannelDescription();
      if (channelDescription) {
        rows.push(['Channel', `"${channelDescription}"`]);
      }
    }
    rows.push([
      'Date',
      TimeHelper.displayDateFromTimestamp(this.get('createdAt'), app.location.get('timezone')),
    ]);

    rows.push([]);

    const orderedItemsByVendorName = _.groupBy(this.get('orderedItems'), (orderedItem) => {
      return orderedItem.vendor ? orderedItem.vendor.name : 'no-vendor';
    });
    const vendorNames = _.sortBy(_.keys(orderedItemsByVendorName));
    _.each(vendorNames, (vendorName) => {
      const orderedItemsSameVendor = orderedItemsByVendorName[vendorName];
      const orderedItemsByIntegrationId = _.groupBy(orderedItemsSameVendor, (orderedItem) => {
        return orderedItem.integrationId;
      });
      _.each(orderedItemsByIntegrationId, (orderedItems) => {
        if (
          !this.get('isUnfinished') &&
          orderedItems[0].integrationId &&
          this.wasSentToIntegrationWithId(orderedItems[0].integrationId)
        ) {
          const checkId = this.getCheckIdForIntegrationWithId(orderedItems[0].integrationId);
          rows.push(['POS Check ID', checkId]);
        }
        _.each(orderedItems, (orderedItem) => {
          rows.push(...this._csvFromOrderedItem(orderedItem));
          if (orderedItem.specialRequest) {
            rows.push(['Special Request', `"${orderedItem.specialRequest}"`]);
          }
          if (orderedItem.recipientName || orderedItem.recipientNumber) {
            rows.push([
              'Recipient',
              `"${orderedItem.recipientName || orderedItem.recipientNumber}"`,
            ]);
          }
        });
      });
    });

    rows.push(['Subtotal', MathHelper.displayPrice(this.get('subTotal'))]);
    if (this.get('discountTotal') >= 0 && this._getDiscountNames().length) {
      rows.push(['Discounts', MathHelper.displayPrice(this.get('discountTotal'))]);
    }
    if (this.has('taxTotal') && app.locationSettings.get('includeTaxTotalOnReceipts')) {
      rows.push(['Tax', MathHelper.displayPrice(this.get('taxTotal'))]);
    }
    if (this.get('tipTotal') > 0) {
      rows.push(['Tip', MathHelper.displayPrice(this.get('tipTotal'))]);
    }
    if (this.get('serviceChargeTotal') > 0) {
      rows.push(['Service Charge', MathHelper.displayPrice(this.get('serviceChargeTotal'))]);
    }
    if (this.get('donationTotal') > 0) {
      rows.push(['Donation', MathHelper.displayPrice(this.get('donationTotal'))]);
    }
    if (this.has('total')) {
      rows.push(['Total', MathHelper.displayPrice(this.get('total'))]);
    }
    if (this.isPaidAtCashier()) {
      rows.push(['Payment', 'Cashier']);
    } else {
      _.each(this.transactions, (transaction) => {
        const tenders = transaction.getTenders();
        rows.push([
          'Payment',
          MathHelper.displayPrice(transaction.get('amount')),
          transaction.getLastFour() || '',
          tenders[0].cardSchemeName,
          transaction.getCardEntryMethodName() || '',
          transaction.get('authCode') || '',
        ]);
      });
    }

    return rows;
  },

  _getLoyaltySummary() {
    const loyaltyI9nDatas = this._getI9nDatasForLoyaltySystems();
    const summaryParts = [];
    if (loyaltyI9nDatas.length && loyaltyI9nDatas[0].sendData) {
      if (loyaltyI9nDatas[0].sendData.madeCheckinAttempt) {
        summaryParts.push('Customer Checkin');
      }
      if (loyaltyI9nDatas[0].sendData.redeemedReward) {
        summaryParts.push('Redeemed Reward');
      }
      if (loyaltyI9nDatas[0].sendData.redeemedCoupon) {
        summaryParts.push('Redeemed Coupon');
      }
    }
    return summaryParts.join('\n');
  },

  _getDiscountNames() {
    const integrationIdSet = {};
    const discountNames = [];
    _.each(this.get('orderedItems'), (orderedItem) => {
      if (orderedItem.integrationId) {
        integrationIdSet[orderedItem.integrationId] = true;
      }
    });
    const integrationIds = Object.keys(integrationIdSet);
    _.each(integrationIds, (integrationId) => {
      const i9nData = this._getI9nDataForId(integrationId);
      _.each(i9nData.discounts, (discount) => {
        discountNames.push(discount.name);
      });
    });

    return discountNames;
  },

  _displayMods(orderedItem, indentationLevel) {
    let pre = '';
    const indent = ' '.repeat(indentationLevel + 1);
    orderedItem.priceOption.addonSets?.forEach((orderedModGroup) => {
      pre += `${indent}<a model-id="${orderedModGroup._id}">${orderedModGroup.name}</a>\n`;

      orderedModGroup.items?.forEach((orderedMod) => {
        pre += `${indent}+ ${this._preTextLineForItem(orderedMod)}`;

        // display nested mods
        pre += this._displayMods(orderedMod, indentationLevel + 2);
      });

      orderedModGroup.deselectedItems?.forEach((orderedMod) => {
        pre += `${indent}- NO ${this._preTextLineForItem(orderedMod)}`;
        pre += this._displayMods(orderedMod, indentationLevel + 2);
      });
    });
    return pre;
  },

  _csvFromOrderedItem(orderedItem, prefix = '') {
    const rows = [];
    const itemName = orderedItem.name.split(/<br[\s/]{0,2}>/).join(' ');
    const unitPrice = MathHelper.displayPrice(orderedItem.priceOption.unitPrice);
    const quantity = orderedItem.priceOption.quantity;
    rows.push([`${prefix}"${itemName}"`, `${quantity}× ${unitPrice}`]);
    _.each(orderedItem.priceOption.addonSets, (modGroup) => {
      rows.push([`"> ${modGroup.name}"`]);
      _.each(modGroup.items, (mod) => {
        rows.push(...this._csvFromOrderedItem(mod, '> '));
      });
    });
    return rows;
  },

  // Need to redefine this because collection.url can have a query
  url() {
    return app.apiPathForLocation(`/orders/${this.id}`);
  },

  requestOrderCancel(callback) {
    const self = this;
    const url = `/api/v2/orders/${this.id}/cancel`;

    app.makeRequestWithOptions({
      method: 'PUT',
      url,
      data: {},
      onSuccess(data) {
        self.set(data.order);
        callback();
      },
      onFailure: callback,
    });
  },

  _getRefundApiVersion() {
    let ordersApiVersion = this.get('ordersApiVersion');
    if (ordersApiVersion === OrdersApiVersion.V1) {
      // The V1 refund API endpoints are deprecated; prefer V2
      ordersApiVersion = OrdersApiVersion.V2;
    }
    return ordersApiVersion.toLowerCase();
  },

  requestOrderRefund(callback) {
    const apiVersion = this._getRefundApiVersion();
    const url = `/api/${apiVersion}/orders/${this.id}/refund`;

    app.QueueHelper.makeRequest('PUT', url, {}, true, (err, data) => {
      if (err) {
        callback(err);
        return;
      }

      this.set(data.order);
      callback();
    });
  },

  requestRefund(transactionId, amount, expectedRemainingAmount, clientId, callback) {
    const apiVersion = this._getRefundApiVersion();
    const url = `/api/${apiVersion}/payments/${transactionId}/refund`;

    const payload = {
      amount,
      expectedRemainingAmount,
      clientId,
    };

    app.QueueHelper.makeRequest('POST', url, payload, true, (err, data) => {
      if (err) {
        callback(err);
        return;
      }

      this.set(data.order);
      callback();
    });
  },

  commitTransaction(transaction, callback) {
    const self = this;
    const url = `${this.url()}/transactions/${transaction.id}/commit`;
    app.QueueHelper.makeRequest('POST', url, null, true, (err, data) => {
      if (err) {
        callback(err);
        return;
      }

      transaction.set(data.transaction);

      const transactions = (self.get('transactions') || []).filter((t) => {
        return t._id !== data.transaction._id;
      });
      transactions.push(data.transaction);
      self.set({ transactions });

      callback();
    });
  },

  syncTransactionStateWithGateway(transactionId, callback) {
    app.makeRequestWithOptions({
      method: 'POST',
      url: `/api/v2/payments/${transactionId}/sync-gateway-state`,
      onSuccess: (data) => {
        this.set({
          ...data.order,
          transactions: data.successfulTransactions,
        });
        callback();
      },
      onFailure: callback,
    });
  },

  requestReceipt(email, callback) {
    app.makeRequestWithOptions({
      method: 'POST',
      url: `${this.url()}/emailReceipt`,
      data: { email },
      onSuccess() {
        callback();
      },
      onFailure: callback,
    });
  },

  requestDisbursement(callback) {
    const url = `/api/v2/payments/${this.id}/disbursement`;
    app.QueueHelper.makeRequest('POST', url, null, true, (err) => {
      if (err) {
        callback(err);
        return;
      }
      callback();
    });
  },

  isPaidAtCashier() {
    return this.get('paymentDestination') === OrderPaymentDestination.Cashier;
  },

  getI9nDatasForSystem(system) {
    return _.filter(this.get('dataForVendors'), (i9nData) => {
      return i9nData.system === system;
    });
  },

  _getIntegratedDataForVendors() {
    return this.get('dataForVendors').filter((i9nData) => {
      if ('system' in i9nData) {
        return true;
      }
      return false;
    });
  },

  _getI9nDatasForLoyaltySystems() {
    return _.filter(this._getIntegratedDataForVendors(), (i9nData) => {
      return I9nSchemaBySystem[i9nData.system].type === 'loyalty';
    });
  },

  _getI9nDataForId(i9nId) {
    return this.get('dataForVendors').find((i9nData) => {
      if (!('integrationId' in i9nData)) {
        return;
      }
      const integrationId = i9nData.integrationId;
      return integrationId === i9nId;
    });
  },

  wasSentToIntegrationWithId(integrationId) {
    const i9nData = this._getI9nDataForId(integrationId);
    return (i9nData?.sentAt || 0) > 0;
  },

  _getLoyaltyCheckId() {
    const loyaltyI9n = _.find(this._getIntegratedDataForVendors(), (i9nData) => {
      return I9nSchemaBySystem[i9nData.system].type === 'loyalty';
    });
    return loyaltyI9n ? this.getCheckIdForIntegrationWithId(loyaltyI9n._id) : null;
  },

  _getFulfillmentCheckId() {
    const fulfillmentI9n = _.find(this._getIntegratedDataForVendors(), (i9nData) => {
      return I9nSchemaBySystem[i9nData.system].type === 'fulfillment';
    });
    return fulfillmentI9n ? this.get('fulfillmentOrderIds')?.join(', ') : null;
  },

  getCheckIdForIntegrationWithId(integrationId) {
    const i9nData = this._getI9nDataForId(integrationId);
    const i9nSchema = I9nSchemaBySystem[i9nData.system];
    if (IntegrationSystem.Olo === i9nData.system) {
      return `${i9nData.sendData.oloOrderId} (basket: ${(this.isOrdersApiV3() ? i9nData.creationData : i9nData.validationData).oloBasketId},
        Olo order #: ${i9nData.sendData.oloId})`;
    }

    let checkIdKey;
    switch (i9nSchema.type) {
      case 'pos':
        checkIdKey = i9nSchema.posCheckIdKey;
        break;
      case 'loyalty':
        checkIdKey = i9nSchema.loyaltyCheckIdKey;
        break;
      case 'fulfillment':
        checkIdKey = i9nSchema.fulfillmentCheckIdKey;
        break;
    }

    if (checkIdKey && (i9nData.sendData || {})[checkIdKey]) {
      const checkId = i9nData.sendData[checkIdKey];
      return checkId;
    }

    return null;
  },

  _preTextLineForItem(orderedItem) {
    const poName = orderedItem.priceOption.name ? `${orderedItem.priceOption.name}: ` : '';
    const priceStr = MathHelper.displayPrice(orderedItem.priceOption.unitPrice);
    const title = `${orderedItem.priceOption.quantity}× ${orderedItem.name}`;
    return `<a model-id="${orderedItem._id}">${title}</a> (${poName}$${priceStr})\n`;
  },
});
