app.ItemArrayItemDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.options.item, 'change', this.render);
  },

  getTitle() {
    const { getDisplayName, item } = this.options;

    return `Update Settings for: ${getDisplayName ? getDisplayName() : item.displayName()}`;
  },

  render() {
    const self = this;
    app.BaseDetailsView.prototype.render.apply(this);

    if (!this.$openButton) {
      const footer = this.superView.footer;
      const buttonTitle = `Open ${this.options.item.isMod() ? 'Mod' : 'Item'} &#x2197;`;
      this.$openButton = footer.addButton(buttonTitle, 'open-item');
      this.$openButton.click(() => {
        self.trigger(app.ModalView.Events.ModalViewShouldHide, self);
        const DetailsView = self.options.item.get('constituentItems')
          ? app.CompoundMenuItemDetailsView
          : app.MenuItemDetailsView;
        const detailsView = new DetailsView({
          model: self.options.item,
          collection: self.options.itemList,
          isReadOnly: self.isReadOnly,
        });
        app.modalManager.showModalWithView(detailsView);
      });
    }

    return this;
  },
});
