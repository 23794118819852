import { KeyCode } from '../../enums/key_code';

app.CompoundModGroupTabView = app.TabView.extend({
  isSingleListTab: true,
  supportsElementRouting: true,

  _bindHotkeys() {
    this.addKeyboardListener(KeyCode.n, () => {
      this.compoundModGroupListView.createNew();
    });
  },

  initialize(options) {
    app.TabView.prototype.initialize.apply(this, arguments);

    this.compoundModGroupListView = new app.CompoundModGroupListView({
      collection: this.collection,
      detailsView: app.CompoundModGroupDetailsView,
      canCreate: options.canCreate,
      tabView: this,
      useVList: true,
      isReadOnly: this.isReadOnly,
      updateUrlWithSelectedModel: () => {
        return true;
      },
    });
    this.setLeftView(this.compoundModGroupListView);

    this._bindHotkeys();
  },

  setSelectedElementId(itemId) {
    this.compoundModGroupListView.setSelectedItemId(itemId);
  },
});
