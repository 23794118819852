import _ from 'underscore';

app.MenuStructureListView = app.BaseListView.extend({
  title: 'Menus',

  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(app.menuStructureList, 'reset', this.render);
    this.listenTo(app.menuTimingList, 'reset', this.render);
  },

  /**
   *
   * @param {app.MenuStructure} model
   * @returns
   */
  createCell(model) {
    const self = this;

    const cell = new app.ComplexCellView({
      model,
      confirmation: 'Are you sure you want to delete this menu?',
      canReorder() {
        return false;
      },
      onClick() {
        app.router.navigate(`menus/${model.id}`, { trigger: true });
      },
      onDelete() {
        model.destroy();
      },
      onRename(name, callback) {
        model.save({ name }, { success: callback });
      },
      deleteError() {
        if (_.contains(app.menu.get('activeMenuTimingIds'), model.get('menuTimingId'))) {
          /** @type {FulfillmentMethod[]} */
          const fulfillmentMethods = model.get('fulfillmentMethods');

          if (fulfillmentMethods && fulfillmentMethods.length) {
            return 'You cannot delete a menu that is currently in use.';
          }
        }
        return null;
      },
      getName() {
        return model.get('name');
      },
      getDescriptionHtml() {
        const descriptionParts = [];

        const timing = app.menuTimingList.get(model.get('menuTimingId'));
        if (timing) {
          descriptionParts.push(`Day Part: ${timing.getSummary()}`);
        }

        const usedFulfillmentMethodNames = model
          .get('fulfillmentMethods')
          .map((fulfillmentMethod) => {
            return app.location.getDiningOptionName(
              fulfillmentMethod,
              true /* showDisabledState */,
            );
          });
        if (usedFulfillmentMethodNames.length) {
          descriptionParts.push(`Dining Options: ${usedFulfillmentMethodNames.join(', ')}`);
        }

        return descriptionParts.join('<br />');
      },
      canRename() {
        return app.sessionUser.canManageMenu();
      },
      canDelete() {
        return app.sessionUser.canManageMenu();
      },
    });
    this.listenTo(model, 'destroy', () => {
      cell.remove();
    });

    if (!model.hasArr('fulfillmentMethods')) {
      cell.addLabel('Please specify dining options', true);
    } else if (!model.get('menuTimingId')) {
      cell.addLabel('Please specify a day part', true);
    } else if (_.contains(app.menu.get('activeMenuTimingIds'), model.get('menuTimingId'))) {
      cell.addLabel('Currently Shown');
    }

    if (app.sessionUser.canManageMenu()) {
      cell.addButton('Dupe', null, ($button) => {
        const reqId = $button.initLoadingButton('Dupe', 'Duping');
        const data = { menuStructureId: model.id };
        // eslint-disable-next-line new-cap
        const newModel = new self.collection.model(
          {},
          {
            collection: self.collection,
          },
        );
        newModel.save(data, {
          error() {
            $button.loadingDidFinishWithError(reqId);
          },
          success() {
            $button.loadingDidFinishSuccessfully(reqId);
            self.collection.add(newModel);
          },
        });
      });
    }

    return cell;
  },
});
