import _ from 'underscore';

import { Time } from '@biteinc/helpers';

import { TimeHelper } from '../../helpers/time_helper';
import { DatePickerHelper } from './date_picker_helper';
import { TimePickerHelper } from './time_picker_helper';

/**
 * @description Allows for selection of a proper timestamp with a date and time picker.
 * The time picker is optional and in that case, this field will just return a timestamp for the
 * beginning of the chosen date in the location's timezone.
 */
app.TimestampFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} timestamp-field-view`;
  },

  setValue(value, model) {
    if (this.schema.useSiteTimezone) {
      // We need to offset the value by the difference of the browser's timezone and the site's
      // timezone so that the timepicker displays the time in the site's timezone.
      value +=
        TimeHelper.localMoment(value).valueOf() -
        TimeHelper.localMoment(value).tz(app.site.get('timezone'), true).valueOf();
    }

    this.model = model;
    this.initialValue = value;
    this.userHasUsedPickers = false;

    if (this.schema.isReadOnly) {
      if (value) {
        this.$dateAndTimeLabel.val(
          TimeHelper.displayDateFromTimestamp(value, app.location.get('timezone')),
        );
      }
    } else if (this.isPermanent()) {
      this.$timeForm?.prop('disabled', true);
      this.$dateForm.prop('disabled', true);
    }

    if (!this.schema.isReadOnly) {
      // Avoid triggering an update event on the very first setDate.
      this.doNotNotifyOfChanges = true;

      if (value) {
        DatePickerHelper.setDatePickerValue(this.$dateForm, value);
        this.$timeForm?.timepicker('setTime', new Date(value));
      } else {
        DatePickerHelper.setDatePickerValue(this.$dateForm, null);
        // TODO How to remove value from timepicker?
      }

      this.doNotNotifyOfChanges = false;
    }

    this.toggleClearButton(value);

    this._prevValue = this.getValue();
  },

  getValue() {
    if (this.schema.isReadOnly && this.initialValue) {
      return this.initialValue;
    }

    if (this.isPermanent()) {
      return null;
    }

    if (!this.userHasUsedPickers) {
      // The user has not used any of the pickers, so the value could not have changed.
      // Return the initial value.
      // This is important since the pickers are rounded, but the value may not be if it was set
      // from the backend, or even if it is null.
      return this.initialValue;
    }

    const dateDate = this.$dateForm.datepicker('getDate');
    if (!dateDate) {
      // The date is required, so if it is not set, return null.
      return null;
    }

    if (this.$timeForm) {
      const timeDate = this.$timeForm.timepicker('getTime', dateDate);
      if (!timeDate) {
        return null;
      }
      const time = timeDate.getTime();
      if (!this.schema.useSiteTimezone) {
        return time;
      }
      return TimeHelper.localMoment(time).tz(app.site.get('timezone'), true).valueOf();
    }

    // We get the timestamp as the beginning of the day in whatever timezone the browser is in.
    // So we must convert it to the location timezone.
    if (dateDate) {
      const simpleDateString = TimeHelper.localMoment(dateDate.getTime()).format('YYYY-MM-DD');
      return Time.moment(simpleDateString, app.location.get('timezone')).valueOf();
    }

    return null;
  },

  getValidationDetails() {
    const value = this.getValue();
    const isEmpty =
      this.userHasUsedPickers &&
      this.$dateForm.val().toString().length === 0 &&
      (!this.$timeForm || this.$timeForm.val().toString().length === 0);
    const hasValue = !!value || (value === null && this.isNullValid());
    const isValid = this.isRequired() ? hasValue && !isEmpty : hasValue !== isEmpty;
    return { value, isEmpty, isValid };
  },

  _checkIfFieldHasChanged() {
    if (this.doNotNotifyOfChanges) {
      return;
    }

    // The user has used one of the pickers, so we can assume that the value may have changed.
    this.userHasUsedPickers = true;

    const value = this.getValue();
    if (!_.isEqual(value, this._prevValue)) {
      this._prevValue = value;
      this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
    }
  },

  render() {
    app.FieldView.prototype.render.apply(this, arguments);

    if (this.schema.isReadOnly) {
      // Use an input field for UI consistency.
      this.$dateAndTimeLabel = $('<input disabled class="form-control"></input>');
      this.$inputContainer.prepend(this.$dateAndTimeLabel);
      return this;
    }

    this.$dateForm = $('<input type="text" class="form-control date" />');
    this.$inputContainer.prepend(this.$dateForm);
    DatePickerHelper.buildDatePicker(
      this.$dateForm,
      {
        ...(!this.schema.allowPastDates && { startDate: new Date() }),
        ...(!this.schema.allowFutureDates && {
          // Add a day to the end date to allow the user to select today.
          endDate: Time.moment(Date.now()).add(1, 'day').toDate(),
        }),
      },
      this._checkIfFieldHasChanged.bind(this),
    );

    if (!this.schema.showDatePickerOnly) {
      this.$timeForm = $('<input type="text" class="form-control time" />');
      this.$inputContainer.prepend(this.$timeForm);

      TimePickerHelper.buildTimePicker(
        this.$timeForm,
        {
          noneOption: false,
        },
        this._checkIfFieldHasChanged.bind(this),
      );
    }

    return this;
  },
});
