import {
  CompoundModGroupSlotType,
  CompoundModGroupSlotTypeHelper,
  CompoundModGroupType,
  CompoundModGroupTypeHelper,
  ModelType,
} from '@biteinc/enums';
import { compoundModGroupSchema } from '@biteinc/schemas';

app.CompoundModGroup = app.AbstractModel.extend({
  ModelName: 'compoundModGroup',
  Schema: compoundModGroupSchema,
  Type: ModelType.CompoundModGroup,

  defaults() {
    const defaults = {
      modGroupIds: [],
    };
    if (app.vendorList && app.vendorList.hasBeenFetched() && app.vendorList.size()) {
      defaults.vendorId = app.vendorList.getDefaultVendor().id;
    }
    return defaults;
  },

  fieldIsHidden(field, subProperty) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    if (app.AbstractModel.prototype.fieldIsHidden.apply(this, arguments)) {
      return true;
    }

    if (fieldName === 'vendorId' && app.vendorList.size() === 1) {
      return true;
    }

    return false;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel, fieldGroupView) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;

    switch (fieldName) {
      case 'modGroupIds': {
        // The mod group list is determined by the currently selected vendor ID.
        // Since it can differ from the value on the model itself, get the value from the form.
        const vendorId = this.fieldGroupView.getValue()['vendorId'] ?? this.get('vendorId');

        return app.modGroupList.buildModGroupsWithVendorList(vendorId);
      }
      case 'removeRegularExtraMods.extraSlot.slotType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CompoundModGroupSlotType,
          nameGenerator: CompoundModGroupSlotTypeHelper.name,
          values: [CompoundModGroupSlotType.Empty, CompoundModGroupSlotType.Real],
        });
      case 'removeRegularExtraMods.regularSlot.slotType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CompoundModGroupSlotType,
          nameGenerator: CompoundModGroupSlotTypeHelper.name,
          values: [CompoundModGroupSlotType.Placeholder, CompoundModGroupSlotType.Real],
        });
      case 'removeRegularExtraMods.removeSlot.slotType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CompoundModGroupSlotType,
          nameGenerator: CompoundModGroupSlotTypeHelper.name,
          values: [
            CompoundModGroupSlotType.Empty,
            CompoundModGroupSlotType.Placeholder,
            CompoundModGroupSlotType.Real,
          ],
        });
      case 'removeRegularExtraMods.extraSlot.modGroupId':
      case 'removeRegularExtraMods.regularSlot.modGroupId':
      case 'removeRegularExtraMods.removeSlot.modGroupId': {
        /** @type {string[]} */
        const modGroupIds = this.fieldGroupView.getValue()['modGroupIds'];

        return new app.ModGroupList(
          app.modGroupList.filter((modGroup) => modGroupIds.includes(modGroup.id)),
        );
      }
      case 'removeRegularExtraMods.extraSlot.modId':
      case 'removeRegularExtraMods.regularSlot.modId':
      case 'removeRegularExtraMods.removeSlot.modId': {
        // The field group view param represents the slot.
        // It has the current mod group ID, mod ID, and type.
        const modGroupId = fieldGroupView.getValue()['modGroupId'];
        const modGroup = modGroupId && app.modGroupList.get(modGroupId);
        const modIds = modGroup ? modGroup.get('items').map((modRef) => modRef._id) : [];

        return new app.ModList(app.modList.filter((mod) => modIds.includes(mod.id)));
      }
      case 'type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CompoundModGroupType,
          nameGenerator: CompoundModGroupTypeHelper.name,
        });
      case 'vendorId':
        return app.vendorList;
    }

    return null;
  },

  addModGroups(modGroupIds) {
    const list = [].concat(this.get('modGroupIds') || []);
    modGroupIds.forEach((modGroupId) => {
      list.push(modGroupId);
    });
    this.set('modGroupIds', list);
  },
});
