app.CompoundModGroupListView = app.BaseListView.extend({
  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    if (!app.vendorList.hasBeenFetched()) {
      this.listenToOnce(app.vendorList, 'reset', () => {
        this.render();
      });
    }
    if (!app.modGroupList.hasBeenFetched()) {
      this.listenToOnce(app.modGroupList, 'reset', () => {
        this.render();
      });
    }
    if (!app.modList.hasBeenFetched()) {
      this.listenToOnce(app.modList, 'reset', () => {
        this.render();
      });
    }

    this.listenTo(app.vendorList, 'change', this.render);
    this.listenTo(app.modGroupList, 'change', this.render);
    this.listenTo(app.modList, 'change', this.render);
  },
});
