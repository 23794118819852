import _ from 'underscore';

import { LanguageCodeHelper } from '@biteinc/enums';
import { MathHelper, StringHelper } from '@biteinc/helpers';

import { TimeHelper } from '../helpers/time_helper';
import { template } from '../template';

app.GuestView = app.ModallableView.extend({
  className: 'guest-view report',
  template: template(
    // prettier-ignore
    '<div class="header">' +
      '<div class="image <@= male @>"></div>' +
      '<div class="info">' +
        '<div class="title"><@= title @></div>' +
        '<div class="summary visit"><@= visitSummary @></div>' +
      '</div>' +
    '</div>' +
    '<div class="summary session">' +
      '<div class="header">SESSION INFO</div>' +
      '<div class="body"><@= sessionSummary @></div>' +
    '</div>' +
    '<div class="summary ordering">' +
      '<div class="header">RECOMMENDATIONS INFO</div>' +
      '<div class="body"><@= orderingSummary @></div>' +
    '</div>' +
    '<div class="summary preferences">' +
      '<div class="header">PREFERENCES INFO</div>' +
      '<div class="body"><@= preferencesSummary @></div>' +
    '</div>' +
    '<div class="summary contact">' +
      '<div class="header">CONTACT INFO</div>' +
      '<div class="body"><@= contactInfo @></div>' +
    '</div>' +
    '<div class="order-history">' +
      '<div class="title">Visit History:</div>' +
      '<div class="order-list"></div>' +
    '</div>',
  ),

  orderTemplate: template(
    // prettier-ignore
    '<div class="order">' +
      '<div class="created-at">Time: <@= createdAt @></div>' +
      '<div class="created-at">Duration: <@= duration @></div>' +
      '<div class="transaction-summary">Sub Total: <@= subTotal @></div>' +
      '<div class="ordered-item-list"></div>' +
    '</div>',
  ),

  initialize(options) {
    options.title = 'Guest Details';
    app.ModallableView.prototype.initialize.apply(this, arguments);

    if (!this.model) {
      this.model = new app.Guest();
      this.model.id = this.options.guestId;
      this.model.fetch();
    }
    this.listenTo(this.model, 'change', this.render);

    this.model.fetchOrders();
  },

  _getVisitSummaryHtml() {
    const summary = this.model.getVisitSummary();
    const lines = [];
    // lines.push(this.model.getEthnicity());
    lines.push(`${summary.visitCount} visit${summary.visitCount === 1 ? '' : 's'}`);
    const avgSpend = MathHelper.displayPrice(summary.avgSpend);
    const totalSpend = MathHelper.displayPrice(summary.totalSpend);
    lines.push(`Average Spend: $${avgSpend}, Total Spend: $${totalSpend}`);

    const avgVisitCount = summary.avgVisitCountPerWeek;
    const avgVisitCountStr = avgVisitCount < 1 ? '< 1' : avgVisitCount;
    lines.push(
      `Visits this week: ${summary.lastWeekVisitCount}, Visits per week: ${avgVisitCountStr}`,
    );
    lines.push(`Guest ID: ${this.model.id}`);
    return lines.join('<br />');
  },

  _getSessionSummaryHtml() {
    const summary = this.model.getSessionSummary();
    return [
      `Quickest Session: ${TimeHelper.durationFromTimestamp(summary.shortestSessionDuration)}`,
      `Average Session: ${TimeHelper.durationFromTimestamp(summary.avgSessionDuration)}`,
      `Longest Session: ${TimeHelper.durationFromTimestamp(summary.longestSessionDuration)}`,
    ].join('<br />');
  },

  _getOrderingSummaryHtml() {
    // TODO: This needs to be better formatted.
    const summary = this.model.getOrderingSummary();
    const lines = [];
    if (summary.topItem) {
      lines.push('<div>Top Item</div>');
      lines.push(app.HtmlHelper.createItemElement(summary.topItem));
    }
    if (_.size(summary.topRecos)) {
      lines.push('<div>Recommended Items</div>');
      _.each(summary.topRecos, (recoItem) => {
        lines.push(app.HtmlHelper.createItemElement(recoItem));
      });
    }
    return lines.join('');
  },

  _getPreferencesSummaryHtml() {
    const summary = this.model.getPreferencesSummary();
    const lines = [];

    const period = summary.timePeriods[0];
    let timeLine = `Visit Hours: ${this._stringFromTimePeriod(period)}`;
    if (period.frequency * 0.75 < summary.timePeriods[1].frequency) {
      timeLine += ` or ${this._stringFromTimePeriod(summary.timePeriods[1])}`;
    }
    lines.push(timeLine);

    if (summary.badgeNames.length) {
      lines.push(`Preferences: ${summary.badgeNames.join(', ')}`);
    }

    lines.push(`Set Language: ${LanguageCodeHelper.name(summary.language)}`);
    lines.push(`Set Text Size: ${StringHelper.toTitleCase(summary.textSize)}`);

    return lines.join('<br />');
  },

  _stringFromTimePeriod(period) {
    if (period.name === 'morning') {
      return 'Morning (8am - 12pm)';
    }
    if (period.name === 'afternoon') {
      return 'Afternoon (12pm - 4pm)';
    }
    return 'Evening (4pm - 11pm)';
  },

  render() {
    app.ModallableView.prototype.render.apply(this, arguments);

    const orders = this.model.getOrders();
    if (orders) {
      this.$el.html(
        this.template({
          title: this.model.displayName(true),
          visitSummary: this._getVisitSummaryHtml(),
          sessionSummary: this._getSessionSummaryHtml(),
          orderingSummary: this._getOrderingSummaryHtml(),
          preferencesSummary: this._getPreferencesSummaryHtml(),
          contactInfo: this.model.getContactInfo().split('\n').join('</br>'),
          male: this.model.gender() === 'Male' ? 'male' : '',
        }),
      );

      // Populate the orders.
      const $orderList = this.$('.order-list');
      orders.forEach((order) => {
        const $order = $(
          this.orderTemplate({
            createdAt: TimeHelper.format(order.get('createdAt')),
            duration: TimeHelper.durationFromTimestamp(order.get('sessionDuration')),
            subTotal: `$${MathHelper.displayPrice(order.get('total') || order.get('subTotal'))}`,
          }),
        );
        $orderList.append($order);

        $order.find('.ordered-item-list').html(
          order.orderedItems
            .map((orderedItem) => {
              return app.HtmlHelper.createItemElement(orderedItem);
            })
            .join(''),
        );
      });
    } else {
      // TODO: style this with a spinner or something
      this.$el.html('<div class="loading">Loading Guest Info</div>');
    }

    return this;
  },
});
