import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';

app.VirtualSubGroupView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} virtual-sub-group-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        const model = options.fieldGroupView.model;

        return {
          _id: StringHelper.randomHexString(24),
          name: 'New Virtual Sub Group',
          minSelectable: 0,
          maxSelectable: 0,
          columnCount: model.get('columnCount'),
          prependPlusSignBeforeModPrices: model.get('prependPlusSignBeforeModPrices'),
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        let title;
        const fields = fieldGroupView.getValue();
        if (_.has(fields, 'name')) {
          title = fields.name;
        }
        return title || '';
      },
      setSetValueHandlers(fieldView, handler) {
        fieldView.fieldViewsByField.name.$form.keyup(handler);
      },
      templateData: {
        title: 'Virtual Sub Groups',
        buttonText: 'New virtual sub group',
        emptyText: 'No virtual sub groups yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
