import { template } from '../../../template';

app.NestedObjectFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} nested-object`;
  },

  template: template(`
    <label class="col-form-label col-md-4"></label>
    <div class="col-md-8 input-container collapsible">
      <button type="button" class="btn btn-secondary configure-button">
        <i class="bi bi-gear-fill" aria-hidden="true" alt="Configure"></i>&nbsp;Configure
      </button>
      <button type="button" class="btn btn-secondary clear-button">
        <i class="bi bi-trash3" aria-hidden="true" alt="Clear value"></i>
      </button>
    </div>
  `),

  initialize() {
    app.FieldView.prototype.initialize.apply(this, arguments);

    this._isConfigured = false;
  },

  getNestedObjectSchema() {
    if (this.schema.fields) {
      return this.schema;
    }
    throw new Error('this method needs to be overridden in a subclass for custom schemas');
  },

  getValue() {
    if (!this._isConfigured) {
      return this.initialValue === undefined ? undefined : null;
    }

    const value = this.nestedFieldGroupView.getValue();
    return value;
  },

  setValue(value, model, superValue) {
    this.initialValue = value;
    if (model) {
      this.model = model;
    }
    if (superValue) {
      this.superValue = superValue;
    }

    if (value) {
      this._setIsConfigured(true);
      this.nestedFieldGroupView.setValue(value, model);
    } else {
      this._setIsConfigured(false);
      this.nestedFieldGroupView.setValue({}, model);
    }
  },

  _setIsConfigured(isConfigured) {
    if (this._isConfigured === isConfigured) {
      return;
    }

    this._isConfigured = isConfigured;
    this._updateConfiguredState();

    this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
  },

  _updateConfiguredState() {
    if (this._isConfigured) {
      this._$clearValueButton.show();
      this._$configureButton.hide();
      this.nestedFieldGroupView.$el.show();
    } else {
      this._$clearValueButton.hide();
      this._$configureButton.show();
      this.nestedFieldGroupView.$el.hide();
    }
  },

  isNullValid() {
    return !!this.schema.isNullable || !this.isRequired();
  },

  checkValidity() {
    if (!this._isConfigured) {
      if (this.isNullValid()) {
        return {
          isValid: true,
        };
      }

      return {
        isValid: false,
        invalidFieldNames: [this.field],
      };
    }

    return this.nestedFieldGroupView.checkValidity();
  },

  render() {
    this.$el.html(this.template());
    this.$label = this.$el.find('label');
    this.$el.attr('id', this.field);

    this.$label.html(`${this.getDisplayName()}:`);

    if (this.schema.tooltip) {
      this.$label.prepend(app.renderTooltip(this.schema.tooltip));
    }

    this._$clearValueButton = this.$('.clear-button');
    this._$clearValueButton.hide();
    this._$clearValueButton.on('click', () => {
      this._setIsConfigured(false);
    });

    this._$configureButton = this.$('.configure-button');
    this._$configureButton.hide();
    this._$configureButton.on('click', () => {
      this._setIsConfigured(true);
    });

    if (!this.nestedFieldGroupView) {
      this.nestedFieldGroupView = new app.FieldGroupView({
        schema: this.getNestedObjectSchema(),
        isReadOnly: this.isReadOnly || this.schema.isReadOnly,
        subProperty: this.subProperty ? `${this.subProperty}.${this.field}` : this.field,
      });
      this.listenTo(
        this.nestedFieldGroupView,
        app.FieldGroupView.Events.FieldGroupDidChangeValue,
        () => {
          this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
        },
      );
    }

    this.$el.append(this.nestedFieldGroupView.render().el);

    // Necessary because FieldGroupView has some fieldGroups with upload views.
    // also because some fields are set asynchronously like this.model etc, this.initialValue
    setTimeout(() => {
      this.nestedFieldGroupView.viewWasAddedToDom();

      this._updateConfiguredState();
    }, 1);

    return this;
  },
});
