app.CompoundModGroupSlotView = app.NestedObjectFieldView.extend({
  render() {
    app.NestedObjectFieldView.prototype.render.apply(this, arguments);

    this.listenTo(
      this.nestedFieldGroupView.fieldViewsByField.modGroupId,
      app.FieldView.Events.FieldDidChangeValue,
      () => {
        // Reset the field by nulling the collection and setting the value to an empty array.
        // This will trigger the field to fetch the collection again with the updated mod group ID.
        this.nestedFieldGroupView.fieldViewsByField.modId.collection = null;
        this.nestedFieldGroupView.fieldViewsByField.modId.setValue(null, this.model);
      },
    );

    return this;
  },
});
