import { KioskDeviceTypeHelper } from '@biteinc/enums';
import { PaymentHelper } from '@biteinc/helpers';

app.KioskDetailsView = app.BaseDetailsView.extend({
  getSchema() {
    const schema = app.JsonHelper.deepClone(app.BaseDetailsView.prototype.getSchema.apply(this));
    if (this.model.isNew()) {
      delete schema.fields.name;
      delete schema.fields.internalName;
      delete schema.fields.prefix;
      delete schema.fields.knownIssues;
      delete schema.fields.forcedBrightness;
      delete schema.fields.touchConfigurationOverride;
    } else {
      const configurations = KioskDeviceTypeHelper.getKioskTouchProfile(
        this.model.get('deviceType'),
      );
      schema.fields.touchConfigurationOverride.fields.dragSensitivityX.tooltip = {
        text: `Default for this device type is ${configurations.dragSensitivityX}. This value dictates the permitted X axis distance from touch down to touch up, where a touch is still considered a touch and not a scroll. Increasing will make the scroll less sensitive.`,
        isWarning: true,
      };

      schema.fields.touchConfigurationOverride.fields.dragSensitivityY.tooltip = {
        text: `Default for this device type ${configurations.dragSensitivityY}. This value dictates the permitted Y axis distance from touch down to touch up, where a touch is still considered a touch and not a scroll. Increasing will make the scroll less sensitive.`,
        isWarning: true,
      };
    }
    return app.removeVendorFields(app.location, schema);
  },

  _updatePaymentFields() {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    const paymentTerminalModel = fieldViews.paymentTerminalModel.getValue();
    const kioskPaymentFields = PaymentHelper.kioskPaymentFields(
      app.location.getKioskPaymentI9nSchema()?.system,
      app.location.getStoredValueI9nSchema()?.system,
      paymentTerminalModel,
      fieldViews.deviceType.getValue(),
    );

    PaymentHelper.allPaymentFields().forEach((paymentField) => {
      let shouldShowField = kioskPaymentFields.includes(paymentField);
      // Hide payment fields if no model is selected
      if ('paymentTerminalModel' !== paymentField && !paymentTerminalModel) {
        shouldShowField = false;
      }
      this._toggleField(paymentField, shouldShowField);
    });
  },

  _toggleField(fieldName, show) {
    if (this.fieldGroupView.fieldViewsByField[fieldName]) {
      this.fieldGroupView.fieldViewsByField[fieldName].$el.toggle(show);
    }
  },

  __fieldGroupDidChangeValue() {
    app.BaseDetailsView.prototype.__fieldGroupDidChangeValue.apply(this, arguments);

    this._updatePaymentFields();
  },

  render() {
    const self = this;

    app.BaseDetailsView.prototype.render.apply(this);

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.paymentTerminalModel,
      app.FieldView.Events.FieldDidChangeValue,
      this._updatePaymentFields,
    );
    this._updatePaymentFields();

    return self;
  },
});
