import _ from 'underscore';

app.PasswordFieldView = app.FieldView.extend({
  setValue(value, model) {
    this.password1FieldView.setValue(value, model);
    this.password2FieldView.setValue(value, model);
  },

  getValue() {
    if (this.password1FieldView.checkValidity().isValid) {
      const password1Value = this.password1FieldView.getValue();
      const password2Value = this.password2FieldView.getValue();
      if (password1Value === password2Value) {
        return password1Value;
      }
    }
    return null;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    const isValid = !!this.getValue();
    return isValid ? { isValid } : { isValid, invalidFieldNames: [] };
  },

  _passwordDidChange() {
    const password1Value = this.password1FieldView.getValue() || '';
    const password2Value = this.password2FieldView.getValue() || '';
    if (password2Value.length > 0 && password1Value !== password2Value) {
      this.password2FieldView.setState(this.states.ERROR, 'Passwords need to match');
    } else if (password2Value.length > 0) {
      this.password2FieldView.setState(this.states.SUCCESS);
    } else {
      this.password2FieldView.setState(this.states.INIT);
    }
  },

  render() {
    this.password1FieldView = new app.FieldView({
      field: 'password',
      schema: this.schema,
    });
    this.$el.append(this.password1FieldView.render().el);
    this.listenTo(
      this.password1FieldView,
      app.FieldView.Events.FieldDidChangeValue,
      this._passwordDidChange,
    );

    const repeatPasswordSchema = _.extend(
      {
        displayName: 'Repeat Password',
      },
      this.schema,
    );
    delete repeatPasswordSchema.tooltip;

    this.password2FieldView = new app.FieldView({
      field: 'repeatPassword',
      schema: repeatPasswordSchema,
    });
    this.$el.append(this.password2FieldView.render().el);
    this.listenTo(
      this.password2FieldView,
      app.FieldView.Events.FieldDidChangeValue,
      this._passwordDidChange,
    );

    app.activateTooltips(this.password1FieldView.$el);

    return this;
  },
});
