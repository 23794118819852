app.CompoundModGroupDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    if (!app.vendorList.hasBeenFetched()) {
      this.listenToOnce(app.vendorList, 'reset', () => {
        this.render();
      });
    }
    if (!app.modGroupList.hasBeenFetched()) {
      this.listenToOnce(app.modGroupList, 'reset', () => {
        this.render();
      });
    }
    if (!app.modList.hasBeenFetched()) {
      this.listenToOnce(app.modList, 'reset', () => {
        this.render();
      });
    }

    this.listenTo(app.vendorList, 'change', this.render);
    this.listenTo(app.modGroupList, 'change', this.render);
    this.listenTo(app.modList, 'change', this.render);
  },

  _resetModFieldViews() {
    const modGroupIds = this.fieldGroupView.fieldViewsByField.modGroupIds.getValue();

    function cleanSlot(slot) {
      if (slot?.modGroupId && !modGroupIds.includes(slot.modGroupId)) {
        slot.modGroupId = null;
        slot.modId = null;
      }
    }

    const modSets = this.fieldGroupView.fieldViewsByField.removeRegularExtraMods.getValue() ?? [];
    modSets.forEach((modSet) => {
      cleanSlot(modSet.removeSlot);
      cleanSlot(modSet.regularSlot);
      cleanSlot(modSet.extraSlot);
    });

    this.fieldGroupView.fieldViewsByField.removeRegularExtraMods.collection = null;
    this.fieldGroupView.fieldViewsByField.removeRegularExtraMods.setValue(modSets, this.model);
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this, arguments);

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.vendorId,
      app.FieldView.Events.FieldDidChangeValue,
      () => {
        // Reset the field by nulling the collection and setting the value to an empty array.
        // This will trigger the field to fetch the collection again with the updated vendor ID.
        this.fieldGroupView.fieldViewsByField.modGroupIds.collection = null;
        this.fieldGroupView.fieldViewsByField.modGroupIds.setValue([], this.model);

        this._resetModFieldViews();
      },
    );

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.modGroupIds,
      app.FieldView.Events.FieldDidChangeValue,
      () => {
        this._resetModFieldViews();
      },
    );

    return this;
  },
});
