import _ from 'underscore';

import {
  allEnumValues,
  FulfillmentMethod,
  FulfillmentMethodHelper,
  ModelType,
  OrderChannel,
} from '@biteinc/enums';
import { Time } from '@biteinc/helpers';
import { diningOptionSchema } from '@biteinc/schemas';

const { fields: DiningOptionFields } = diningOptionSchema;

app.DiningOptionView = app.AccordionFieldGroupView.extend({
  initialize() {
    app.AccordionFieldGroupView.prototype.initialize.apply(this, arguments);

    if (!app.openHoursOverrideList || !app.openHoursOverrideList.hasBeenFetched()) {
      app.openHoursOverrideList.fetch();
      this.listenTo(app.openHoursOverrideList, 'reset', this.render);
    }
    if (!app.openHoursTimetableList || !app.openHoursTimetableList.hasBeenFetched()) {
      app.openHoursTimetableList.fetch();

      this.listenTo(app.openHoursTimetableList, 'reset', this.render);
    }
  },

  getOrderThrottlingLabel(orderThrottlingRule) {
    return `${orderThrottlingRule.name} ${Time.stringTimeFromDayTimeStamp(
      orderThrottlingRule.timePeriod.from,
    )}-${Time.stringTimeFromDayTimeStamp(
      orderThrottlingRule.timePeriod.to,
    )} ${Time.dateStringsFromWeekdays(orderThrottlingRule.weekdays)}.${
      orderThrottlingRule.isEnabled ? '' : ' (Disabled)'
    }`;
  },

  render() {
    app.AccordionFieldGroupView.prototype.render.apply(this);

    const { fulfillmentMethod } = this.keyModel;
    const applicableThrottlingRules = app.site
      .get('orderThrottlingRules')
      ?.filter((orderThrottlingRule) => {
        return orderThrottlingRule.fulfillmentMethods.includes(fulfillmentMethod);
      });
    if (_.size(applicableThrottlingRules)) {
      const applicableRulesValueString = applicableThrottlingRules
        .map((orderThrottlingRule) => {
          return this.getOrderThrottlingLabel(orderThrottlingRule);
        })
        .join('<br />');

      const $displayGroup = $('<div class="field-group"></div>');
      const label = 'Applicable Order Throttling Rules:';
      const $valueDisplay = `
      <div class="field-container">
        <label class="col-form-label col-md-4">${label}</label>
          <div class="col-md-8">
            <span class="simple-value">${applicableRulesValueString}</span>
          </div>
      </div>`;
      $displayGroup.append($valueDisplay);
      this.$el.append($displayGroup);
    }

    if (app.sessionUser.canViewMenuLibrary() && !!app.location.getDiningOption(fulfillmentMethod)) {
      // Only show the open-hours link for users who have the required right to fetch them and if
      // the POS does not sync the open hours
      const canEditOpenHours =
        app.sessionUser.canManageMenu() &&
        !app.location?.syncsModelsWithType(ModelType.OpenHoursTimetable);
      const $timetableDisplayGroup = $('<div class="field-group"></div>');
      const $timetableDisplay = `
      <div class="field-container">
        <label class="col-form-label col-md-4">Open Hours:</label>
        <div class="col-md-8">
          <a><span>
            Click to ${canEditOpenHours ? 'Edit' : 'View'}
          </span></a>
        </div>
      </div>`;
      $timetableDisplayGroup.append($timetableDisplay);
      this.$el.append($timetableDisplayGroup);

      // We can't use the Model-Id-Was-Clicked event to create the pop-up because that requires us
      // to know the model ID during rendering.
      // Since the open-hours objects come in a later request, we will have to find the model and
      // open the modal ourselves.
      $timetableDisplayGroup.find('a').click(() => {
        const openHoursTimetable = app.openHoursTimetableList.findWhere({ fulfillmentMethod });
        const detailsView = new app.BaseDetailsView({
          collection: app.openHoursTimetableList,
          model: openHoursTimetable,
          isReadOnly: !app.sessionUser.canManageMenu(),
        });
        app.modalManager.showModalWithView(detailsView);
      });
    }

    return this;
  },
});

app.DiningOptionsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} dining-option-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          const kioskOnly = options.schema.tabId === 'kiosk-settings';
          // Return all possible fulfillment methods
          const isKioskFlashLocation = app.location.get('orderChannel') === OrderChannel.Kiosk;
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: FulfillmentMethod,
            nameGenerator: FulfillmentMethodHelper.name,
            values: allEnumValues(FulfillmentMethod).filter((fulfillmentMethod) => {
              if (kioskOnly) {
                return (
                  FulfillmentMethodHelper.orderChannel(fulfillmentMethod) === OrderChannel.Kiosk
                );
              }
              if (isKioskFlashLocation) {
                return (
                  FulfillmentMethodHelper.orderChannel(fulfillmentMethod) === OrderChannel.Flash
                );
              }
              return (
                FulfillmentMethodHelper.orderChannel(fulfillmentMethod) ===
                app.location.get('orderChannel')
              );
            }),
          });
        },
        getNewElementFromModel: (fulfillmentMethodModel) => {
          const fulfillmentMethod = fulfillmentMethodModel.id;
          return {
            fulfillmentMethod,
            isEnabled: true,
            customerIdentifierOptions: [],
            ...(FulfillmentMethodHelper.isAnOutpost(fulfillmentMethod) && {
              futureOrdersEnabled: true,
            }),
          };
        },
        canShowModel: (fulfillmentMethodModel, elements) => {
          // Filter out the fulfillment methods that are already entered (but maybe not saved)
          return !_.findWhere(elements, { fulfillmentMethod: fulfillmentMethodModel.id });
        },
      },
      getTitle: (fieldGroupView) => {
        const { fulfillmentMethod, name, isEnabled } = fieldGroupView.getValue();
        // Don't use the helper to construct the name because we don't need the channel info since
        // we are in a channel specific view
        const fulfillmentNameLabel = FulfillmentMethodHelper.name(fulfillmentMethod);
        const finalName = name ? `${name} (${fulfillmentNameLabel})` : fulfillmentNameLabel;
        if (!isEnabled) {
          return `${finalName} (Disabled)`;
        }
        return finalName;
      },
      getElementSchemaFields: (/* element */) => {
        return DiningOptionFields;
      },
      FGVClass: app.DiningOptionView,
      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new dining option',
        emptyText: 'no dining options yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
