import _ from 'underscore';

import { BuildType } from '@biteinc/enums';

function getLocationListFromFilter(filterQuery) {
  const locationIdsFromKiosks = app.kioskList
    ? app.kioskList
        .filter((kiosk) => {
          return kiosk.matchesQuery(filterQuery);
        })
        .map((kiosk) => {
          return kiosk.attributes.locationId;
        })
    : [];

  const locationList = app.orgList.getAllLiveLocations().filter((location) => {
    return locationIdsFromKiosks.includes(location.id) || location.matchesQuery(filterQuery);
  });

  return locationList;
}

app.BiteView = app.TabbedView.extend({
  defaultTabId: 'orgs',
  requiresPageHeader: true,

  initialize() {
    app.TabbedView.prototype.initialize.apply(this, arguments);

    app.orgList = new app.OrgList();
    const orgListView = new app.OrgListView({
      collection: app.orgList,
      canCreate: app.sessionUser.isBiteSupport(),
    });
    const orgTab = new app.TabView();
    orgTab.setLeftView(orgListView);
    this.addTab(orgTab, 'Orgs', 'orgs');

    if (app.sessionUser.canManageUsers()) {
      app.userRoleList = new app.UserRoleList(
        _.map(app.data.userRoles, (userRoleJson) => {
          return new app.UserRole(userRoleJson);
        }),
      );
      app.userList = new app.UserList();
      const userListView = new app.UserListView({
        collection: app.userList,
        detailsView: app.UserDetailsView,
        canCreate: app.sessionUser.isBiteEng(),
        fetchOnInit: false,
      });
      const userTab = new app.TabView();
      userTab.setLeftView(userListView);
      this.addTab(userTab, 'Bite Users ⚒', 'bite-users');
    }

    // we don't want to show the map on mobile view
    const isMobileView = window.innerWidth < 768;

    let debounceTimer;
    let kioskMapView;
    if (!isMobileView) {
      kioskMapView = new app.KioskMapView();
    }

    function localSearchCallback(query) {
      // The callback is fired multiple times on search, so we debounce it
      if (!debounceTimer) {
        debounceTimer = setTimeout(() => {
          const locationList = getLocationListFromFilter(query);

          kioskMapView.setLocationsToDisplay(locationList);
          debounceTimer = null;
        }, 500);
      } else {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          const locationList = getLocationListFromFilter(query);

          kioskMapView.setLocationsToDisplay(locationList);
          debounceTimer = null;
        }, 500);
      }
    }

    app.kioskList = new app.KioskList();
    const kioskListView = new app.KioskListView({
      canCreate: false,
      cellView: app.KioskCellView,
      collection: app.kioskList,
      fetchOnInit: false,
      ...(!isMobileView && {
        localSearchCallback,
      }),
      updateUrlWithSearchQuery: true,
    });

    const kiosksTab = new (app.TabView.extend({
      setSelectedElementId(query) {
        this.leftView.setQuery(query);
      },
    }))();
    kiosksTab.supportsElementRouting = true;
    kiosksTab.setLeftView(kioskListView);

    if (!isMobileView) {
      kiosksTab.setRightView(kioskMapView);
    }

    this.addTab(kiosksTab, 'Kiosks', 'kiosks');

    this.addLinkWithHandler('Hype Board', () => {
      window.open('/v2/hypeboard', '_blank');
    });

    this.addLinkWithHandler('Cellar', () => {
      window.open('/cellar', '_self');
    });

    this.addLinkWithHandler('Product Builder', () => {
      window.open('/v2/product-builder', '_blank');
    });

    this.addSpacer();

    if (app.sessionUser.isBiteSupport()) {
      this.addSeparatorWithTitle('Builds ⚒');

      app.gcnBuildList = new app.BuildList([], { buildType: BuildType.Gcn });
      const gcnBuildListView = new app.BuildListView({
        canCreate: false,
        searchEnabled: false,
        cellView: app.BuildCellView,
        cellOptions: {
          collection: app.gcnBuildList,
        },
        collection: app.gcnBuildList,
        fetchOnInit: false,
      });
      const gcnBuildTab = new app.TabView();
      gcnBuildTab.setLeftView(gcnBuildListView);
      this.addTab(gcnBuildTab, 'GCN', 'gcn-builds');

      app.vitrineBuildList = new app.BuildList([], { buildType: BuildType.Vitrine });
      const vitrineBuildList = new app.BuildListView({
        canCreate: false,
        searchEnabled: false,
        cellView: app.BuildCellView,
        cellOptions: {
          collection: app.vitrineBuildList,
        },
        collection: app.vitrineBuildList,
        fetchOnInit: false,
      });
      const vitrineBuildTab = new app.TabView();
      vitrineBuildTab.setLeftView(vitrineBuildList);
      this.addTab(vitrineBuildTab, 'Vitrine', 'vitrine-builds');

      app.garconBuildList = new app.BuildList([], { buildType: BuildType.Garcon });
      const garconBuildListView = new app.BuildListView({
        canCreate: false,
        searchEnabled: false,
        cellView: app.BuildCellView,
        cellOptions: {
          collection: app.garconBuildList,
        },
        collection: app.garconBuildList,
        fetchOnInit: false,
      });
      const garconBuildTab = new app.TabView();
      garconBuildTab.setLeftView(garconBuildListView);
      this.addTab(garconBuildTab, 'Garcon', 'garcon-builds');

      app.chromeOSBuildList = new app.BuildList([], { buildType: BuildType.ChromeOS });
      const chromeOSBuildListView = new app.BuildListView({
        canCreate: false,
        searchEnabled: false,
        cellView: app.BuildCellView,
        cellOptions: {
          collection: app.chromeOSBuildList,
        },
        collection: app.chromeOSBuildList,
        fetchOnInit: false,
      });
      const chromeOSBuildTab = new app.TabView();
      chromeOSBuildTab.setLeftView(chromeOSBuildListView);
      this.addTab(chromeOSBuildTab, 'ChromeOS', 'chromeos-builds');
    }

    this.addSpacer();

    const toolsTab = new app.TabView();
    toolsTab.setLeftView(new app.ToolsView());
    toolsTab.setRightView(new app.InternalToolsView());
    this.addTab(toolsTab, 'Tools & Search', 'tools');

    this.addLinkWithHandler('Settings Dashboard', () => {
      window.open('/v2/settings-dashboard', '_self');
    });

    const systemHealthTab = new app.TabView();
    const systemHealthView = new app.SystemHealthView({
      tabView: systemHealthTab,
    });
    systemHealthTab.setLeftView(systemHealthView);
    this.addTab(systemHealthTab, 'System Health', 'system-health');

    if (app.sessionUser.isBiteEng()) {
      this.addLinkWithHandler('Queues ⚙', () => {
        window.open('/arena/queues', '_self');
      });
    }

    this.addBottomLinks(false /* help */, true /* changePassword */);
  },
});
