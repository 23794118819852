import { LanguageCode, ModelType } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { CustomStringKey, Strings } from '@biteinc/localization';
import { appearanceSchema } from '@biteinc/schemas';

app.Appearance = app.AbstractModel.extend({
  ModelName: 'appearance',
  Schema: appearanceSchema,
  Type: ModelType.Appearance,

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'customCssBuildId':
        return app.customCssGcnBuildList;
      case 'customCssVitrineBuildId':
        return app.customCssVitrineBuildList;
      case 'orgAppearanceId':
        if (!this.get('locationId')) {
          return null;
        }
        return app.orgAppearanceList;
      case 'customStrings':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CustomStringKey,
          nameGenerator: (customStringKey) => {
            return StringHelper.toTitleCase(customStringKey.split('_').join(' ').toLowerCase());
          },
        });
    }
    return null;
  },

  subtitleForListFieldElement(field, element) {
    switch (field) {
      case 'customStrings':
        return Strings[element.id][LanguageCode.EN_US] || '[no default value]';
    }
    return '';
  },

  url() {
    if (!this.id) {
      return '/api/v2/appearances';
    }
    return `/api/v2/appearances/${this.id}`;
  },
});
